import { Button, Modal, Text, Grid, InjectedModalProps, useToast, Input } from '@pancakeswap/uikit'
import Link from 'next/link'
import Image from 'next/image'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

export const ModelNotify = ({ onDismiss }: { onDismiss: () => void }) => {
  const { account } = useActiveWeb3React()
  var newLocal = []
  const handleCancel = () => {
    if (!localStorage.getItem('isVotedCore')) {
      newLocal.push(account)
      localStorage.setItem('isVotedCore', JSON.stringify(newLocal))
      onDismiss()
    } else {
      const isVotes = JSON.parse(localStorage.getItem('isVotedCore'))
      newLocal.push(...isVotes, account)
      localStorage.setItem('isVotedCore', JSON.stringify(newLocal))
      onDismiss()
    }
  }

  const handleOk = () => {
    if (!localStorage.getItem('isVotedCore')) {
      newLocal.push(account)
      localStorage.setItem('isVotedCore', JSON.stringify(newLocal))
      onDismiss()
    } else {
      const isVotes = JSON.parse(localStorage.getItem('isVotedCore'))
      newLocal.push(...isVotes, account)
      localStorage.setItem('isVotedCore', JSON.stringify(newLocal))
      onDismiss()
    }
  }
  return (
    <Modal title="Announcement!" onDismiss={handleCancel} style={{ padding: '10px' }}>
      <div style={{ display: 'flex', marginTop: '50px', justifyContent: 'center' }}>
        <Image src="/images/core/Logo.png" alt="" width="100px" height="40px" />
      </div>
      <Grid alignItems="center" justifyContent="center" mt="20px">
        <h1 style={{ color: 'white', fontWeight: 600, fontSize: '20px', textAlign: 'center' }}>Dear Core Stakers,</h1>
        <br />
        <h1 style={{ color: 'white', fontWeight: 600, fontSize: '16px', textAlign: 'center' }}>
          Fusion Upgrade for CORE and Bitcoin Stakers: <br /> <br />
          The Fusion Upgrade is coming soon! This upgrade introduces two groundbreaking features—Dual Staking and
          LstBTC—set to redefine BTCfi. <br /> <br />
          Prepare to welcome this launch event and the robust growth of the CST token, designed specifically for the
          development of the community. <br /> <br />
          As a result, the profit-sharing commission for Core staking will be temporarily paused. Commissions will
          resume once everything stabilizes. <br /> <br />
          Note: Direct recruitment commissions are not affected. <br /> <br />
          Sincerely!
        </h1>
        <br />
      </Grid>
    </Modal>
  )
}
