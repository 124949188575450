import { Flex, Stack, Tooltip, useDisclosure } from '@chakra-ui/react'
import { formatEther } from '@ethersproject/units'
import { Button, LinkExternal, Text, useMatchBreakpoints, useToast } from '@pancakeswap/uikit'
import CircleLoader from 'components/Loader/CircleLoader'
import { ToastDescriptionWithTx } from 'components/Toast'
import addresses from 'config/constants/contracts'
import { color } from 'configs/color'
import { ethers } from 'ethers'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import useClaimTransaction from 'hooks/useClaimTransaction'
import useConfirmTransaction from 'hooks/useConfirmTransaction'
import numeral from 'numeral'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { getBlockExploreLink } from 'utils'
import { Text02, Text03 } from '../../../configs/fornt'
import Section2 from '../Section2'
import { usePoolsContract, usePoolsV2Contract } from 'hooks/useContract'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 50px;
  z-index: 2;
  background-image: url('/images/core/section1.png');
  background-size: cover;
  background-position: 50% 100%;
  @media (max-width: 768px) {
    padding: 10px;
  }
`

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 16px;
  width: 100%;
  // margin: 5px 0 10px 0;
  border-radius: 4px;
  border: 1px solid var(--Colors-Stroke-Border-Border, #2f2e39);
  background: #0a0b0c;
`
const InpuStake = styled.input`
  font-family: 'Blinker', sans-serif;
  background: none;
  border: none;
  color: ${color.textWhite};
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  height: 36px;
  &:focus-visible {
    outline-width: 0;
  }
`
const ContentText = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  @media screen and (max-width: 575px) {
    font-size: 20px;
  }
`
const Box = styled.div`
  border-radius: 8px;
  background: #1d1617;
`
const Section1 = () => {
  const CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)
  const { account, chainId } = useActiveWeb3React()
  // account = '0xa76cC130532Dbb51Ba112349418bBB9910773B4E'
  const [isHovered, setIsHovered] = useState(false)
  const { isMobile, isTablet } = useMatchBreakpoints()
  const [loading, setLoading] = useState(false)
  const [loadingDeposit, setLoadingDeposit] = useState(false)
  const [loadingClaim, setLoadingClaim] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const getPoolsContract = usePoolsContract()
  const getPoolsContractV2 = usePoolsV2Contract()
  const [pools, setPools] = useState(0)
  const [coreAmount, setCoreAmount] = useState(0)
  const [amounts, setAmounts] = useState(0)
  const { toastSuccess, toastError } = useToast()
  const [showError, setShowError] = useState(false)
  const { callWithGasPrice } = useCallWithGasPrice()
  const [accountBalance, setAccountBalance] = useState(0)
  // const [poolHistory, setPoolHistory] = useState(getPoolsContractV2)
  const [claimCT, setClaimCT] = useState(1)
  const [poolsCT, setPoolsCT] = useState(getPoolsContractV2)
  const [corePrice, setCorePrice] = useState(0)
  const [showCurrentReward, setShowCurrentReward] = useState(0)
  // const [period, setPeriod] = useState(0)
  const [dataPools, setDataPools] = useState([])
  const newPools = useRef('0')
  const [minAmounts, setMinAmounts] = useState(0)
  const [systemData, setSystemData] = useState({
    totalMiner: 0,
    defaultTrend: 0,
    totalMined: 0,
    totalClaimed: 0,
  })
  const getMineSystem = async () => {
    const totalMiner = await getPoolsContract.totalMiner()
    const totalMined = await getPoolsContract.totalMined()
    const totalClaimed = await getPoolsContract.totalClaimed()
    const trend2USDT = await getPoolsContract.CST2USDT()
    setSystemData({
      totalMiner: Number(formatEther(totalMiner)),
      defaultTrend: Number(formatEther(trend2USDT)),
      totalMined: Number(formatEther(totalMined)),
      totalClaimed: Number(formatEther(totalClaimed)),
    })
  }
  const [dataUsers, setDataUsers] = useState({
    TotalLockUSD: 0,
    totalRewardsUSD: 0,
    remainComm: 0,
  })

  const timeStamp = Math.floor(Date.now() / 1000)
  // deposit
  const { hasConfirmFailed, isConfirming, handleConfirm } = useConfirmTransaction({
    onConfirm: () => {
      // setLoadingDeposit(true)
      // getPoolsUsers()
      return callWithGasPrice(getPoolsContractV2, 'deposit', [pools], {
        value: ethers.utils.parseUnits(coreAmount.toString(), 'ether').toString(),
      })
    },
    onSuccess: async ({ receipt }) => {
      getData()
      setLoadingDeposit(false)
      getDataUsers()
      getPoolsUsers()

      toastSuccess('Deposit successfully !', <ToastDescriptionWithTx txHash={receipt.transactionHash} />)
    },
  })
  // claim Comms

  const { isConfirmingClaim, handleConfirmClaim } = useClaimTransaction({
    onConfirm: () => {
      setLoadingClaim(true)
      return callWithGasPrice(claimCT === 2 ? getPoolsContractV2 : getPoolsContract, 'claimComm', [account])
    },
    onSuccess: async ({ receipt }) => {
      getData()
      getDataUsers()
      setLoadingClaim(false)
      toastSuccess('Claim successfully !', <ToastDescriptionWithTx txHash={receipt.transactionHash} />)
    },
  })
  // check amonut
  const handleChange = (e) => {
    const amount = Number(e)
    setAmounts(amount)
    setCoreAmount(amount / corePrice + 0.01)
    if (amount / corePrice > Number(accountBalance)) {
      setShowError(true)
    } else {
      setShowError(false)
    }
  }
  // get balance
  const rpc = process.env.NEXT_PUBLIC_SNAPSHOT_BASE_URL || 'https://rpc-core.icecreamswap.com'

  // get User Info
  const getDataUsers = async () => {
    const provider = new ethers.providers.StaticJsonRpcProvider(rpc)
    const getPoolBalance = await provider.getBalance(account)
    const core2USDT = await getPoolsContract.CORE2USDT()
    setAccountBalance(Number(formatEther(getPoolBalance)))
    const [
      TotalLockUSD,
      totalRewardsUSD,
      usermaxOutUSD,
      totalComms,
      TotalLockUSD2,
      totalRewardsUSD2,
      usermaxOutUSD2,
      totalComms2,
    ] = await Promise.all([
      getPoolsContract.userTotalLockUSD(account),
      getPoolsContract.totalRewardsUSD(account),
      getPoolsContract.usermaxOutUSD(account),
      getPoolsContract.totalComms(account),
      getPoolsContractV2.userTotalLockUSD(account),
      getPoolsContractV2.totalRewardsUSD(account),
      getPoolsContractV2.usermaxOutUSD(account),
      getPoolsContractV2.totalComms(account),
    ])
    const commClaimed = Number(formatEther(totalComms)) + Number(formatEther(totalComms2))
    const maxOutUSD = Number(formatEther(usermaxOutUSD)) + Number(formatEther(usermaxOutUSD2))
    const maxOut = maxOutUSD / Number(formatEther(core2USDT))
    const percentage = maxOutUSD > 0 ? (commClaimed / maxOut) * 100 : 0
    const remainComm = percentage < 100 ? await getPoolsContract.remainComm(account) : 0
    const remainCommV2 = percentage < 100 ? await getPoolsContractV2.remainComm(account) : 0
    if (Number(formatEther(remainCommV2)) > Number(formatEther(remainComm))) {
      setClaimCT(2)
    }

    setDataUsers({
      TotalLockUSD: Number(formatEther(TotalLockUSD.add(TotalLockUSD2))),
      totalRewardsUSD: Number(formatEther(totalRewardsUSD.add(totalRewardsUSD2))),
      remainComm:
        account.toLowerCase() === '0x38557988753cF98148EBde0AFf7da18D1a4456a0'.toLowerCase() ||
        account.toLowerCase() === '0xa95BD305e7eBc149705935A3F4C0c9Aea1B06625'.toLowerCase() ||
        account.toLowerCase() === '0x302847c83be2aAf3E2a3568723E96D7e610ad5e7'.toLowerCase() ||
        account.toLowerCase() === '0x6fD308655aFBBa1aBf752D0CbFb90AF7ABBbebe0'.toLowerCase() ||
        account.toLowerCase() === '0x26cF75395411e8fd0f1F83C0574d2A5Ad96844C4'.toLowerCase()
          ? // account.toLowerCase() !== '0x5829EcAdC511e984b76d53dd8dd65C4b3F959DeE'.toLowerCase() &&
            // account.toLowerCase() !== '0x1D0196D62301cB4B5Ac5524146A8C1D6CAA73835'.toLowerCase() &&
            // account.toLowerCase() !== '0x6502926ECc13623c418e60e588F76522E5a32fD2'.toLowerCase()
            Number(formatEther(remainComm)) + Number(formatEther(remainCommV2))
          : 0,
    })
  }
  // get data staked
  const getData = async () => {
    const core2USDT = await getPoolsContract.CORE2USDT()
    const pools = await getPoolsContract.getPools([2])

    setMinAmounts(Number(formatEther(pools[0].minLock)))
    setAmounts(Number(formatEther(pools[0].minLock)))
    setCoreAmount(Number(formatEther(pools[0].minLock)) / Number(formatEther(core2USDT)) + 0.001)
    setCorePrice(Number(formatEther(core2USDT)))
  }
  // get info pools history
  const getPoolsUsers = async () => {
    try {
      setLoading(true)
      const poolsUsers = await getPoolsContract.getPoolsUsers(account, [0, 1, 2, 3, 4, 5, 6, 7, 8, 9])
      const poolsUsersV2 = await getPoolsContractV2.getPoolsUsers(account, [0, 1, 2, 3, 4, 5, 6, 7, 8, 9])
      const period = await getPoolsContract.period()
      // setPeriod(Number(period))
      const stakedsV1 = poolsUsers
        .map((x, i) => {
          const y = { ...x }
          y.pid = i
          y.contract = getPoolsContract
          return y
        })
        .filter((x) => Number(x.totalLockUSD) > 0)
      const stakedsV2 = poolsUsersV2
        .map((x, i) => {
          const y = { ...x }
          y.pid = i
          y.contract = getPoolsContractV2
          return y
        })
        .filter((x) => Number(x.totalLockUSD) > 0)
      const stakeds = stakedsV1.concat(stakedsV2)
      // setPoolHistory()
      getDataUsers()

      getStartTime(stakedsV1.length > 0 ? poolsUsers : poolsUsersV2, 2, period)
      // setDataTotalLock(poolsUsers)
      if (stakeds.length > 0) {
        const currentRewards = await Promise.all(stakeds.map((x) => getPoolsContract.currentReward(x.pid, account)))
        const currentRewards2 = await Promise.all(stakeds.map((x) => getPoolsContractV2.currentReward(x.pid, account)))
        const getPoolsInfo = await Promise.all(stakeds.map((x) => getPoolsContract.pools(x.pid)))
        const totalCurrentReward = currentRewards.reduce((a, b) => a.add(b))
        const totalCurrentReward2 = currentRewards2.reduce((a, b) => a.add(b))
        setShowCurrentReward(Number(formatEther(totalCurrentReward.add(totalCurrentReward2))))
        const newPoolInfo = await Promise.all(
          stakeds.map(async (items, r) => {
            const currentReward = await items.contract
              .currentReward(items.pid, account)
              .then((rs) => Number(formatEther(rs)))

            return {
              currentReward,
              currentInterest: ((Number(getPoolsInfo[r].currentInterest.toString()) / 10000) * 365).toFixed(2),
              startTime: Number(items.startTime),
              contract: items.contract,
              totalLock: Number(formatEther(items.totalLock)),
              remain: Number(formatEther(items.remainReward)),
              totalReward: Number(formatEther(items.totalReward)),
              totalLockUSD: Number(formatEther(items.totalLockUSD)),
              remainUSD: Number(formatEther(items.remainRewardUSD)),
              poolId: items.pid,
            }
          }),
        )
        setDataPools(newPoolInfo)
      } else {
        setShowCurrentReward(0)
        setIsLoading(true)
        setDataPools([])
      }
    } catch (error) {
      setLoading(true)
    }
  }
  const getStartTime = (data, index, period) => {
    const startTimes = Number(data[index].startTime)
    if (index < data.length) {
      if (startTimes === 0) {
        setPools(index)
        return
      }
      if (Number(timeStamp - startTimes) < Number(7 * Number(period))) {
        setPools(index)
        return
      }
      index++
      // eslint-disable-next-line
      return getStartTime(data, index, period)
    }
  }

  useEffect(() => {
    if (account) {
      getPoolsUsers()
    }
    getMineSystem()
    getData()
  }, [account])

  return (
    <>
      <Wrapper>
        <Flex
          m={isMobile ? 0 : '50px 0'}
          direction={isMobile ? 'column' : isTablet ? 'column' : 'row'}
          p={isMobile ? '30px 10px' : isTablet ? '30px' : '0 50px'}
        >
          <Stack w={isMobile ? '100%' : isTablet ? '10period0%' : '50%'}>
            {account ? (
              <Flex
                w={isMobile ? '100%' : '25%'}
                borderRadius="30px"
                bg="rgba(0, 0, 0, 0.16)"
                alignItems="center"
                justifyContent="center"
                p="8px 16px 8px 12px"
                gap="10px"
              >
                <img src="/images/core/online.svg" />
                <Text
                  style={{
                    color: '#CBF43D',
                    fontFamily: 'Poppins,sans-serif',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '150%',
                  }}
                >
                  Available
                </Text>
              </Flex>
            ) : (
              <></>
            )}

            <Text
              style={{
                color: '#FFF',
                fontFamily: 'Poppins,sans-serif',
                fontSize: '56px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '130%',
                width: '300px',
              }}
            >
              Simple & Secure
            </Text>
            <LinkExternal
              fontSize={['14px', '16px', '18px', '20px', '22px']}
              href={getBlockExploreLink('0x40375C92d9FAf44d2f9db9Bd9ba41a3317a2404f', 'address', CHAIN_ID)}
              ellipsis={true}
              style={{ color: '#fff', gap: '8px' }}
              color="#fff"
              className="link"
            >
              <Text02 style={{ color: 'rgba(255, 255, 255, 0.70)' }}>Contract:</Text02>
              <Text02 className="textCore">{`${'0x40375C92d9FAf44d2f9db9Bd9ba41a3317a2404f'.substring(
                0,
                4,
              )}...${'0x40375C92d9FAf44d2f9db9Bd9ba41a3317a2404f'.substring(
                '0x40375C92d9FAf44d2f9db9Bd9ba41a3317a2404f'.length - 16,
              )}`}</Text02>
            </LinkExternal>
            <LinkExternal
              fontSize={['14px', '16px', '18px', '20px', '22px']}
              href={getBlockExploreLink('0x3206867F6b9e50d651A6ce50060283BCa0aA507C', 'address', CHAIN_ID)}
              ellipsis={true}
              style={{ color: '#fff', gap: '8px' }}
              color="#fff"
              className="link"
            >
              <Text02 style={{ color: 'rgba(255, 255, 255, 0.70)' }}>Contract:</Text02>
              <Text02 className="textCore">{`${'0x3206867F6b9e50d651A6ce50060283BCa0aA507C'.substring(
                0,
                4,
              )}...${'0x3206867F6b9e50d651A6ce50060283BCa0aA507C'.substring(
                '0x3206867F6b9e50d651A6ce50060283BCa0aA507C'.length - 16,
              )}`}</Text02>
            </LinkExternal>
            <LinkExternal
              fontSize={['14px', '16px', '18px', '20px', '22px']}
              href={getBlockExploreLink(getPoolsContractV2.address, 'address', CHAIN_ID)}
              ellipsis={true}
              style={{ color: '#fff', gap: '8px' }}
              color="#fff"
              className="link"
            >
              <Text02 style={{ color: 'rgba(255, 255, 255, 0.70)' }}>Contract:</Text02>
              <Text02 className="textCore">{`${getPoolsContractV2.address.substring(
                0,
                4,
              )}...${getPoolsContractV2.address.substring(getPoolsContractV2.address.length - 16)}`}</Text02>
            </LinkExternal>
            <Stack>
              <Text02 style={{ fontWeight: 600 }}>Build for Core, Stake to Earn more!</Text02>
              {/* <Flex w={isMobile ? '100%' : '50%'} gap="10px" mt="20px">
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '50px',
                  justifyContent: 'center',
                  padding: '30px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: isMobile ? '5px' : isTablet ? '10px' : '15px',
                    alignItems: 'center',
                  }}
                >
                  <img src="/images/core/minner.svg" />
                  <ContentText
                    style={{
                      fontSize: '12px',
                      fontWeight: 700,
                    }}
                  >
                    <CountUp
                      start={0}
                      preserveValue
                      delay={0}
                      end={Number(systemData.totalMiner)}
                      duration={0.5}
                      separator=","
                      style={{
                        color: '#E2E1E5',
                        fontSize: '25px',
                        fontWeight: '700',
                        lineHeight: '44px',
                      }}
                    />{' '}
                    <span
                      style={{
                        color: '#E2E1E5',
                        fontSize: '15px',
                        fontWeight: 400,
                        lineHeight: '44px',
                        opacity: 0.7,
                        marginLeft: '5px',
                      }}
                    >
                      Users
                    </span>
                  </ContentText>{' '}
                </div>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px',
                }}
              >
                <img width="50px" src="/images/core/MetaMask.svg" />
              </Box>
            </Flex> */}
            </Stack>
          </Stack>
          <Stack w={isMobile ? '100%' : isTablet ? '100%' : '50%'} mt={isMobile ? '20px' : isTablet ? '20px' : 0}>
            <Stack borderRadius="8px" border="2px solid rgba(249, 184, 69, 0.27)" background="#1D1617" p="24px">
              <Text>
                Balance:{' '}
                <span
                  style={{
                    background: 'linear-gradient(180deg, #FEF0AE 2.17%, #FAC600 108.56%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  {accountBalance > 100 ? numeral(accountBalance).format('0,0') : accountBalance.toFixed(4)} Core
                </span>
              </Text>
              <InputGroup>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}></div>
                <InpuStake onChange={(e) => handleChange(e.target.value)} value={amounts}></InpuStake>

                <div
                  // onClick={() => setAmounts(Number(Number(accountBalance).toFixed(3)))}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    // cursor: 'pointer',
                    // background: '#535353',
                    padding: '6px',
                    borderTopRightRadius: '10px',
                    borderBottomRightRadius: '10px',
                  }}
                >
                  {/* <img style={{ width: '24px', height: '24px' }} src="/images/core/IconCore.png" /> */}
                  <Flex p="8px 25px 8px 10px" bg="#2F292A" borderRadius="4px" gap="10px">
                    <img src="/images/core/ustdIcon.svg" />
                    <div
                      style={{
                        fontSize: 'Poppins,sans-serif',
                        fontWeight: 400,
                        color: '#fff',
                      }}
                    >
                      USDT
                    </div>
                  </Flex>
                </div>
              </InputGroup>
              {amounts < minAmounts && (
                <Text
                  style={{
                    color: 'red',
                  }}
                >
                  Minimum is {minAmounts}
                </Text>
              )}
              <Flex direction={isMobile ? 'column' : 'row'} justifyContent="center" gap="10px">
                <Stack borderRadius="4px" p="12px" gap="8px" bg="#2F292A" w="full">
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={400}
                    lineHeight="24px"
                  >
                    Core Amount
                  </Text>
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={600}
                    lineHeight="24px"
                  >
                    {numeral(coreAmount).format('0,0.000')} CORE
                  </Text>
                </Stack>
                <Stack borderRadius="4px" p="12px" gap="8px" bg="#2F292A" w="full">
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={400}
                    lineHeight="24px"
                  >
                    Staked Amount
                  </Text>
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={600}
                    lineHeight="24px"
                  >
                    $ {Number(dataUsers.TotalLockUSD).toFixed(2)}
                  </Text>
                </Stack>
                <Stack borderRadius="4px" p="12px" gap="8px" bg="#2F292A" w="full">
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={400}
                    lineHeight="24px"
                  >
                    Current Reward
                  </Text>
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={600}
                    lineHeight="24px"
                  >
                    {Number(showCurrentReward) !== 0
                      ? Number(Number(showCurrentReward) - Number(showCurrentReward) * 0.025).toFixed(2)
                      : 0}
                  </Text>
                </Stack>
              </Flex>

              <Button
                style={{
                  width: '100%',
                  borderRadius: '4px',
                  background: '#FF9400',
                  color: '#fff',
                  marginTop: '10px',
                }}
                onClick={handleConfirm}
                // disabled={
                //   showError || Number(amounts) <= 0 || coreAmount > Number(accountBalance) || amounts < minAmounts
                // }
              >
                {isConfirming ? (
                  <Stack justifyContent="center" alignItems="center">
                    <CircleLoader size="30px" stroke="white" />
                  </Stack>
                ) : hasConfirmFailed ? (
                  <Text fontWeight={600}>Enable Staking</Text>
                ) : (
                  <Text fontWeight={600}>Enable Staking</Text>
                )}
              </Button>

              <Flex direction={isMobile ? 'column' : 'row'} gap="10px" mt="10px">
                <Flex
                  w={isMobile ? '100%' : '50%'}
                  borderRadius="4px"
                  bg="#2F292A"
                  p="12px"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Text>Commission</Text>
                  <Text03 style={{ color: '#F9F9F9' }}>
                    {Number(dataUsers.remainComm) !== 0 ? Number(dataUsers.remainComm).toFixed(3) : 0}
                  </Text03>
                </Flex>
                {loadingClaim === true && isConfirmingClaim ? (
                  <Button
                    style={{
                      width: isMobile ? '100%' : '50%',
                      cursor: 'not-allowed',
                      borderRadius: '4px',
                      border: '1px solid #FF9400',
                      background: '#2c1f10',
                    }}
                  >
                    <Stack justifyContent="center" alignItems="center">
                      <CircleLoader size="30px" stroke="white" />
                    </Stack>
                  </Button>
                ) : (
                  <Button
                    style={{
                      width: isMobile ? '100%' : '50%',
                      position: 'relative',
                      cursor: 'pointer',
                      borderRadius: '4px',
                      border: '1px solid #FF9400',
                      background: 'linear-gradient(180deg, #01070D  0%, #FF9400  300%)',
                    }}
                    onClick={handleConfirmClaim}
                    disabled={Number(dataUsers.remainComm) <= 0}
                  >
                    <Stack position="absolute" w="3px" h="18px" bg="#FF9400" left="0" />
                    <div
                      style={{
                        color: '#FF9400',
                      }}
                    >
                      Claim Commission
                    </div>
                  </Button>
                )}
              </Flex>
            </Stack>

            <Flex direction={isMobile ? 'column' : 'row'} w="100%" gap="10px">
              <Flex
                borderRadius="8px"
                bg="#1D1617"
                p="24px"
                gap="30px"
                w={isMobile ? '100%' : '50%'}
                alignItems="center"
              >
                <img src="/images/core/lockFrame.svg" />
                <Stack>
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="14px"
                    fontStyle="normal"
                    fontWeight={400}
                    lineHeight="24px"
                  >
                    Anticipated
                  </Text>
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="20px"
                    fontStyle="normal"
                    fontWeight={700}
                    lineHeight="120%"
                  >
                    24 Month
                  </Text>
                </Stack>
              </Flex>
              <Flex
                borderRadius="8px"
                bg="#1D1617"
                p="24px"
                gap="30px"
                w={isMobile ? '100%' : '50%'}
                alignItems="center"
              >
                <img src="/images/core/profitFrame.svg" />
                <Stack>
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="14px"
                    fontStyle="normal"
                    fontWeight={400}
                    lineHeight="24px"
                  >
                    Annual Percentage Yield
                  </Text>
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="20px"
                    fontStyle="normal"
                    fontWeight={700}
                    lineHeight="120%"
                  >
                    170%
                  </Text>
                </Stack>
              </Flex>
            </Flex>
            <Stack borderRadius="8px" bg="#1D1617" p="24px">
              <Text fontWeight={600} color="#fff">
                Apply DAO mechanism exclusively for Node investors to participate in voting when Core reaches ATH $20.
                If there&apos;s 51% consensus, the unlocking of the CORE Liquidity Pool will take place. All users can
                Claim their remaining rewards.
              </Text>

              <Stack
                p="13px"
                textAlign="center"
                sx={{
                  width: '100%',
                  borderRadius: '4px',
                  background: '#FF9400',
                  color: '#fff',
                  marginTop: '10px',
                  cursor: 'pointer',
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <Text fontWeight={600}>{isHovered ? 'Please come back when CORE hits $20' : 'Vote'}</Text>
              </Stack>
            </Stack>
          </Stack>
        </Flex>
      </Wrapper>
      <Section2 dataPools={dataPools} poolsCT={poolsCT} getPoolsUsers={getPoolsUsers} isLoading={isLoading} />
    </>
  )
}
export default Section1
