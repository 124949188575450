import { multicallAddresses } from '@pancakeswap/multicall'

export default {
  multiCall: multicallAddresses,
  refferal: {
    56: '0x9Aa12B0853a8AEF18744A30BAB11c8DF6D83d5CF',
    97: '0xc24B642357D7Dd1bBE33F3D8Aa0101DFA2cf6EB9',
    137: '0xfAB7689862c459e33a2192DdAd27d5c4a8A2a0C2',
    1115: '0x2c2F755a5af4D830f522478E28650AD2Cd5499B7',
    1116: '0x8D22dd915CB51B60f911f7a34704b137291Ba2a2',
  },
  pools: {
    97: '0x7e2385eE582F5700df9dd1C649dc530Cb141d6c9',
    137: '0x849ACE7457cae40cd9B0e2C253bdb23357ecb523',
    56: '',
    1115: '',
    1116: '0x570C0Fe1e9d8FfC6B79547b85E44a833faFD380A',
  },
  poolsV2: {
    97: '0x7e2385eE582F5700df9dd1C649dc530Cb141d6c9',
    137: '0x76763fD3723b0E8B552d9AaaF93BcC78cD999e8b',
    56: '',
    1115: '',
    1116: '0x8Af7f95DD21E39282C17e1e17d71753c09e96Ab1',
  },
  USDTPools: {
    97: '0x7e2385eE582F5700df9dd1C649dc530Cb141d6c9',
    137: '0x76763fD3723b0E8B552d9AaaF93BcC78cD999e8b',
    56: '',
    1115: '',
    1116: '0xD82EFaa298Ad996Eaf15F238ECD189ee5CF5709B',
  },
  readCode: {
    97: '0x7e2385eE582F5700df9dd1C649dc530Cb141d6c9',
    137: '0x76763fD3723b0E8B552d9AaaF93BcC78cD999e8b',
    56: '',
    1115: '',
    1116: '0xb43f4C3f97Da4852F8fB2CcBAFc17e9382BFEe86',
  },
  vote: {
    137: '0x49A0E77eB25655391A4E6C35fce30E383C04F1a3',
    1115: '',
    1116: '0x6E4234bD88c9B4ecf6098ac2165f7765313cFB6D',
  },
  tokenCore: {
    56: '',
    97: '0xB500E7f7FEb8787b7a32f09787Aa12d60B695aD7',
    1115: '',
    1116: '0xc24B642357D7Dd1bBE33F3D8Aa0101DFA2cf6EB9',
  },
  tokenUSDT: {
    56: '',
    97: '0xB500E7f7FEb8787b7a32f09787Aa12d60B695aD7',
    1115: '',
    1116: '0x900101d06A7426441Ae63e9AB3B9b0F63Be145F1',
  },
}
