/* eslint-disable react/button-has-type */
import { formatEther } from '@ethersproject/units'
import { Button, LinkExternal, Text, useMatchBreakpoints, useModal } from '@pancakeswap/uikit'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import styled from 'styled-components'
import { color } from 'configs/color'
import { usePoolsContract } from 'hooks/useContract'
import { useEffect, useRef, useState } from 'react'
import CountUp from 'react-countup'
import numeral from 'numeral'
import TableDataPool from './components/yourMineHistory'
import ClaimPoolModal from './components/ClaimModal'
import { getContract } from 'utils/contractHelpers'
import addresses from 'config/constants/contracts'
import tokenCST from 'config/abi/tokenCore.json'
import { useSigner } from 'wagmi'
import SendTrendModal from './components/sendModal'
import { Text02, Text03 } from 'configs/fornt'
import { getBlockExploreLink } from 'utils'
import { ButtonStake } from 'components/ButtonStake'
import Breadcrumb from 'components/Breadcrumb'
import { Flex, Stack } from '@chakra-ui/react'
import poolV2Abi from 'config/abi/poolsV2.json'
const responsiveTextSizeHeader = ['20px', '24px', '25px', '32px', '48px']

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  justify-content: center;
  // background: linear-gradient(90deg, #9e86ff 0%, #2b0864 111.24%);
  // background: black;
  background: url('/images/core/MiningCST.png') no-repeat;
  background-size: cover;
  margin-top: 50px;
  gap: 32px;
  /* height: 1200px; */
  margin-bottom: 20px;
  @media screen and (max-width: 575px) {
    margin-top: 50px;
    background-position: 50% 100%;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
  max-width: 1240px;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    margin-top: 0px !important;
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column !important;
  }
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }

  margin-top: 70px;
`

const IntroWapper = styled.div`
  display: flex;
  /* flex-direction: row; */
  justify-content: flex-start;
  align-items: start;
  .icon {
    margin-top: 60px;
    width: 24px;
    height: 24px;
  }
  .link:hover {
    text-decoration: none;
  }
  @media screen and (max-width: 575px) {
    flex-direction: column;
    .icon {
      margin-top: 30px;
    }
  }
`

const Title = styled.div`
  font-size: 60px;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: -0.02em;
  text-align: center;
  /* letter-spacing: -1.2px; */
  margin-top: 30px;
  color: white;
  @media screen and (max-width: 575px) {
    font-size: 24px;
    text-align: center;
    line-height: 32px;
  }
`

const IntrotText = styled.div`
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  width: 100%;
  @media screen and (max-width: 900px) {
    text-align: start;
  }
  @media screen and (max-width: 575px) {
    width: 90%;
    font-size: 15px;
    text-align: start;
    line-height: 18px;
  }
`

const Table = styled.div`
  width: 50%;
  min-height: 490px;
  height: 100%;
  display: flex;
  border-radius: 8px;
  border: 2px solid transparent;
  background-image: linear-gradient(#1d1617, #1d1617), linear-gradient(180deg, #ff9400 0%, #d97059 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
  .animation {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  .animation .hos-wrapper {
    background-image: url('/images/core/Oki1.png');
    background-size: cover;
    background-position: 50% 50%;
    width: 80%;
    height: 556px;
    position: relative;
  }
  .hos {
    position: absolute;
    width: 100%;
    height: 556px;
    top: 0;
    left: 0;
    object-fit: cover;
    animation: 2s linear 0s infinite forwards running hos;
    transform-box: fill-box;
    transform-origin: 50% 50%;
  }
  @keyframes hos {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    .animation .hos-wrapper {
      width: 80%;
      height: 556px;
    }
    .hos {
      width: 100%;
      height: 556px;
    }
  }
  @media screen and (max-width: 575px) {
    flex-direction: column;
    .animation .hos-wrapper {
      width: 100%;
      height: 356px;
    }
    .hos {
      width: 100%;
      height: 356px;
    }
  }
`
const TableSystem = styled.div`
  min-height: 490px;
  width: 585px;
  padding: 40px;
  border-radius: 20px;
  border: 2px solid transparent;
  background-image: linear-gradient(#1d1617, #1d1617), linear-gradient(180deg, #ff9400 0%, #d97059 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  @media screen and (max-width: 575px) {
    flex-direction: column;
    width: 100%;
    margin-top: 50px;
    padding: 20px;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    margin-top: 50px;
    padding: 20px;
  }
`
const BoxContain = styled.div`
  min-height: 400px;
  padding: 40px;
  border-radius: 15px;
  border: 1px;
  gap: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 900px) {
    gap: 16px;
    width: 100%;
    padding: 20px;
  }
  @media screen and (max-width: 575px) {
    gap: 16px;
    width: 100%;
    padding: 20px;
  }
`
const Box = styled.div`
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
`
const ContentText = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  @media screen and (max-width: 575px) {
    font-size: 20px;
  }
`

const TableMine = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 4px solid transparent;
  background-image: linear-gradient(#1d1617, #1d1617), linear-gradient(180deg, #f9b845 27%, #af452c 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  max-width: 1240px;
  width: 95%;
  backdrop-filter: blur(50px);
  height: 100%;
  margin-bottom: 150px;
`
const SystemContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 575px) {
    flex-direction: column;
    align-items: center;
  }
`
const TokenTitle = styled(Text)`
  font-size: 24px;
  background: linear-gradient(180deg, #ff8000 0%, #f8a68e 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  @media screen and (max-width: 575px) {
    font-size: 16px;
  }
`
const BreadcrumbTitle = styled(Text)`
  color: rgba(255, 255, 255, 0.7);
  opacity: 0.5;
  font-family: Poppins;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 575px) {
    margin-top: 30px;
  }
`
function Mining() {
  const { isMobile, isTablet, isTabletOrDesktop } = useMatchBreakpoints()
  let { account, chainId } = useActiveWeb3React()
  // account = '0x76C077894aEd4EE4fb848036b8533aec2e1FE792'
  const { data: signer } = useSigner()
  const CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)
  const getPoolsContract = usePoolsContract()
  const getPoolsContractV2 = getContract({
    address: addresses.poolsV2[CHAIN_ID],
    abi: poolV2Abi,
    chainId: CHAIN_ID,
    signer,
  })
  const tokenCSTCT = getContract({ address: addresses.tokenCore[CHAIN_ID], abi: tokenCST, chainId: CHAIN_ID, signer })
  const [isLoading, setIsLoading] = useState(false)
  const [CST2USDT, setCST2USDT] = useState(0)
  const [claimCT, setClaimCT] = useState(getPoolsContractV2)
  const [usersClaimed, setUserClaimed] = useState([])
  const [mineData, setMineData] = useState({
    totalMined: 0,
    claimed: 0,
    remain: 0,
    mineSpeed: 0,
    startTime: 0,
    userClaimedMineLength: 0,
    currentReward: 0,
    CST2USDT: 0,
    balanceCST: 0,
  })
  const [available, setAvailable] = useState({
    total: 0,
    show: 0,
  })
  const [systemData, setSystemData] = useState({
    totalMiner: 0,
    defaultTrend: 0,
    totalMined: 0,
    totalClaimed: 0,
  })
  // const [isFirst, setIsFirst] = useState(false)
  const isFirst = useRef(false)
  const getMineSystem = async () => {
    const [totalMiner, totalMined, totalClaimed, trend2USDT, totalMinerV2, totalMinedV2, totalClaimedV2] =
      await Promise.all([
        getPoolsContract.totalMiner(),
        getPoolsContract.totalMined(),
        getPoolsContract.totalClaimed(),
        getPoolsContract.CST2USDT(),
        getPoolsContractV2.totalMiner(),
        getPoolsContractV2.totalMined(),
        getPoolsContractV2.totalClaimed(),
      ])
    setSystemData({
      totalMiner: Number(totalMiner) + Number(totalMinerV2),
      defaultTrend: Number(formatEther(trend2USDT)),
      totalMined: Number(formatEther(totalMined)) + Number(formatEther(totalMinedV2)),
      totalClaimed: Number(formatEther(totalClaimed)) + Number(formatEther(totalClaimedV2)),
    })
  }
  const [openSendModal, onDismissSendModal] = useModal(
    <SendTrendModal onDismiss={() => onDismissSendModal} mine={mineData} />,
    true,
    false,
    'removeModal',
  )
  const initAvailable = async (total) => {
    const avai = {
      total,
      show: total - total / 2880,
    }
    setAvailable(avai)
    updateAvailable(avai)
  }
  const updateAvailable = (avai) => {
    const newAvai = {
      ...avai,
      show: avai.show + ((avai.total - avai.show) * 10) / 100,
    }
    if (isFirst.current === true) {
      setTimeout(async () => {
        if (avai.show > 0) setAvailable(newAvai)
        updateAvailable(newAvai)
      }, 3000)
    } else {
      setAvailable({
        total: 0,
        show: 0,
      })
    }
  }
  const getAvailable = async () => {
    if (!account) {
      setAvailable({
        total: 0,
        show: 0,
      })
    } else {
      const currentRewardCSTV1 = await getPoolsContract.currentRewardCST(account)
      const currentRewardCSTV2 = await getPoolsContractV2.currentRewardCST(account)
      if (Number(formatEther(currentRewardCSTV1)) > Number(formatEther(currentRewardCSTV2))) {
        setClaimCT(getPoolsContract)
      } else {
        setClaimCT(getPoolsContractV2)
      }
      const total = Number(formatEther(currentRewardCSTV1)) + Number(formatEther(currentRewardCSTV2))
      if (Number(total) === 0) {
        // setIsFirst(false)
        isFirst.current = false
        setAvailable({
          total: 0,
          show: 0,
        })
      } else {
        // setIsFirst(true)
        isFirst.current = true
        initAvailable(total)
      }
    }
  }
  const getPrice = async () => {
    const price = await getPoolsContract.CST2USDT()
    setCST2USDT(price.toString())
  }

  const getData = async () => {
    const [usersMine, currentRewardCST, usersClaimMinedLength, balanceCST] = await Promise.all([
      await getPoolsContract.usersMine(account),
      await getPoolsContract.currentRewardCST(account),
      await getPoolsContract.getUsersClaimMinedLength(account),
      await tokenCSTCT.balanceOf(account),
    ])
    const [usersMineV2, currentRewardCSTV2, usersClaimMinedLengthV2] = await Promise.all([
      await getPoolsContractV2.usersMine(account),
      await getPoolsContractV2.currentRewardCST(account),
      await getPoolsContractV2.getUsersClaimMinedLength(account),
    ])
    setMineData({
      totalMined: usersMine.totalMined.add(usersMineV2.totalMined),
      claimed: usersMine.claimed.add(usersMineV2.claimed),
      remain: Number(formatEther(usersMine.remain)) + Number(formatEther(usersMineV2.remain)),
      mineSpeed: Number(usersMineV2.mineSpeed) > 0 ? Number(usersMineV2.mineSpeed) : Number(usersMine.mineSpeed),
      startTime: Number(usersMineV2.startTime) > 0 ? Number(usersMineV2.startTime) : Number(usersMine.startTime),
      userClaimedMineLength:
        Number(usersClaimMinedLengthV2) > 0 ? Number(usersClaimMinedLengthV2) : Number(usersClaimMinedLength),
      currentReward: Number(formatEther(currentRewardCST)) + Number(formatEther(currentRewardCSTV2)),
      CST2USDT: Number(formatEther(CST2USDT)),
      balanceCST: Number(formatEther(balanceCST)),
    })
    getMineHistory(usersClaimMinedLength, usersClaimMinedLengthV2)
  }
  const [openClaimModal, onDismissModal] = useModal(
    <ClaimPoolModal
      onDismiss={() => onDismissModal()}
      onSuccess={() => handleSuccess()}
      mine={mineData}
      claimCt={claimCT}
    />,
    true,
    false,
    'removeModal',
  )
  const handleSuccess = () => {
    getData()
    getAvailable()
  }
  const mergeData = (newData) => {
    setUserClaimed((prevData) => [...prevData, ...newData])
  }
  const getMineHistory = async (getUsersClaimMinedLength, getUsersClaimMinedLengthV2) => {
    try {
      if (!account) {
        setIsLoading(true)
      } else {
        setIsLoading(false)

        if (Number(getUsersClaimMinedLength) > 0 || Number(getUsersClaimMinedLengthV2) > 0) {
          const currenRewardV1 = await getPoolsContract.currentRewardCST(account)
          const currenRewardV2 = await getPoolsContractV2.currentRewardCST(account)
          const totalCurrentReward = Number(formatEther(currenRewardV1)) + Number(formatEther(currenRewardV2))
          const CSTUSD = await getPoolsContract.CST2USDT()
          await getPoolsContract.getUsersClaimMined(account, Number(getUsersClaimMinedLength), 0).then((res) => {
            const arr = res.list.map((claimed: any, i: number) => {
              return {
                date: Number(claimed.date.toString()),
                amount: Number(formatEther(claimed.amount)),
                totalLock: Number(formatEther(claimed.totalLock)),
                power: Number(claimed.interrest.toString()) / 100,
                currentReward: Number(totalCurrentReward),
                rateUSD: Number(formatEther(CSTUSD)),
              }
            })
            mergeData(arr)
          })
          await getPoolsContractV2.getUsersClaimMined(account, Number(getUsersClaimMinedLengthV2), 0).then((res) => {
            const arrV2 = res.list.map((claimed: any, i: number) => {
              return {
                date: Number(claimed.date.toString()),
                amount: Number(formatEther(claimed.amount)),
                totalLock: Number(formatEther(claimed.totalLock)),
                power: Number(claimed.interrest.toString()) / 100,
                currentReward: Number(totalCurrentReward),
                rateUSD: Number(formatEther(CSTUSD)),
              }
            })
            mergeData(arrV2)
          })
        }
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    if (account) {
      getData()
    }
    getMineSystem()
    getAvailable()
    getPrice()
  }, [account])
  useEffect(() => {
    if (!account) {
      setMineData({
        totalMined: 0,
        claimed: 0,
        remain: 0,
        mineSpeed: 0,
        startTime: 0,
        userClaimedMineLength: 0,
        currentReward: 0,
        CST2USDT: 0,
        balanceCST: 0,
      })
    }
  }, [account])
  return (
    <Wrapper>
      <Container>
        <BreadcrumbTitle>
          <Breadcrumb
            fontSize="14px"
            links={[
              { title: 'Home', href: '/' },
              { title: 'Mining', href: '/mining' },
            ]}
            current="mining"
          />
        </BreadcrumbTitle>
        <IntroWapper>
          <Flex direction={isMobile ? 'column' : isTablet ? 'column' : 'row'} justifyContent="space-between" w="full">
            <Stack spacing={0}>
              <Flex alignItems="star" justifyContent="flex-star" gap="10px">
                <Title>Mining</Title>

                <LinkExternal
                  fontSize={['14px', '16px', '18px', '20px', '22px']}
                  href={getBlockExploreLink(tokenCSTCT.address, 'token', CHAIN_ID)}
                  ellipsis={true}
                  style={{ color: '#00F0E1' }}
                  color="#00F0E1"
                  className="link"
                >
                  <Title>CST </Title>
                </LinkExternal>
              </Flex>
              <IntrotText>
                Blockchain mining validates transactions, secures the network, and maintains decentralized integrity.
              </IntrotText>
            </Stack>
            <Flex
              mt={isMobile ? '20px' : 'none'}
              p="30px 70px 30px 30px"
              borderRadius="8px"
              border="2px solid transparent"
              background="#1d1617"
              gap="30px"
              sx={{
                backgroundImage:
                  'linear-gradient(#1d1617, #1d1617), linear-gradient(180deg, #F9B845 27%, #AF452C 100%)',
                backgroundOrigin: 'border-box',
                backgroundClip: 'padding-box, border-box',
              }}
            >
              <img src="/images/core/mining.svg" />
              <Stack spacing={0}>
                <Text>Your Speed</Text>
                <ContentText
                  style={{
                    fontSize: isMobile ? '20px' : isTablet ? '36px' : '24px',
                    fontWeight: 700,
                  }}
                >
                  {' '}
                  {Number(mineData.mineSpeed / 100)}
                  <span style={{ fontSize: '26px', fontWeight: 700 }}>x</span>
                </ContentText>
              </Stack>
            </Flex>
          </Flex>
        </IntroWapper>
        <Flex direction={isMobile ? 'column' : isTablet ? 'column' : 'row'} mt="30px" gap="30px">
          <Table>
            <BoxContain>
              <Flex alignItems="center" gap="20px">
                <img src="/images/core/walletFrame.svg" />
                <ContentText
                  style={{
                    fontSize: '24px',
                    lineHeight: isMobile ? '32px' : '38px',
                    fontWeight: '700',
                    color: '#FFF',
                  }}
                >
                  Your Wallet
                </ContentText>
              </Flex>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: isMobile ? '16px' : '32px',
                  order: isMobile ? 2 : 1,
                  width: '100%',
                }}
              >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <ContentText>Remained CST (Locking)</ContentText>
                  <Flex alignItems="center" gap="10px">
                    <Box
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: isMobile ? '5px' : isTablet ? '10px' : '15px',
                          alignItems: 'center',
                        }}
                      >
                        <img src="/images/core/cstIcon.svg" alt="" />
                        {!account ? (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: '10px',
                            }}
                          >
                            <ContentText
                              style={{
                                fontSize: isMobile ? '15px' : '18px',
                                fontWeight: 700,
                              }}
                            >
                              0
                            </ContentText>{' '}
                          </div>
                        ) : (
                          <>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '10px',
                              }}
                            >
                              <ContentText
                                style={{
                                  fontSize: isMobile ? '15px' : '18px',
                                  fontWeight: 700,
                                }}
                              >
                                <CountUp
                                  start={0}
                                  preserveValue
                                  delay={0}
                                  end={Number(formatEther(mineData.totalMined)) - Number(formatEther(mineData.claimed))}
                                  decimals={mineData.totalMined > 0 ? 1 : 0}
                                  duration={0.5}
                                  separator=","
                                />{' '}
                                CST
                              </ContentText>
                            </div>
                          </>
                        )}
                      </div>
                    </Box>
                    ~
                    <Box
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: isMobile ? '5px' : isTablet ? '10px' : '15px',
                          alignItems: 'center',
                        }}
                      >
                        {!account ? (
                          <Flex alignItems="center" gap="10px">
                            <img src="/images/core/ustdIcon.svg" />

                            <ContentText
                              style={{ fontSize: isMobile ? '15px' : '18px', fontWeight: 600, color: '#FFFFFF' }}
                            >
                              0 USDT
                            </ContentText>
                          </Flex>
                        ) : (
                          <Flex alignItems="center" gap="10px">
                            <img src="/images/core/ustdIcon.svg" />

                            <ContentText
                              style={{
                                fontSize: isMobile ? '15px' : '18px',
                                fontWeight: 600,
                                color: '#fff',
                              }}
                            >
                              <CountUp
                                start={0}
                                preserveValue
                                delay={0}
                                end={
                                  (Number(formatEther(mineData.totalMined)) - Number(formatEther(mineData.claimed))) *
                                  Number(formatEther(CST2USDT))
                                }
                                decimals={Number(formatEther(mineData.totalMined)) > 0 ? 6 : 0}
                                duration={0.5}
                                separator=","
                              />{' '}
                              USDT
                            </ContentText>
                          </Flex>
                        )}
                      </div>
                    </Box>
                  </Flex>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: isMobile ? '16px' : '32px',
                  order: isMobile ? 2 : 1,
                  width: '100%',
                }}
              >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <Flex justifyContent="space-between" w="full">
                    <ContentText>Your Wallet Balance</ContentText>
                    <button
                      onClick={openSendModal}
                      disabled={mineData.balanceCST <= 0}
                      style={{
                        // backdropFilter: 'blur(50px)',
                        alignSelf: 'center',
                        background: 'none',
                      }}
                    >
                      <ContentText
                        style={{
                          fontWeight: 700,
                          fontSize: 16,
                          textTransform: 'uppercase',
                          background: 'linear-gradient(180deg, #FEF0AE 2.17%, #FAC600 108.56%)',
                          backgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                        }}
                      >
                        Send
                      </ContentText>
                    </button>
                  </Flex>
                  <Flex alignItems="center" gap="10px">
                    <Box
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: isMobile ? '5px' : isTablet ? '10px' : '15px',
                          alignItems: 'center',
                        }}
                      >
                        <img src="/images/core/cstIcon.svg" alt="" />

                        {!account ? (
                          <ContentText
                            style={{
                              fontSize: isMobile ? '15px' : '18px',
                              fontWeight: 700,
                            }}
                          >
                            0 USDT
                          </ContentText>
                        ) : (
                          <ContentText style={{ fontSize: isMobile ? '15px' : '18px', fontWeight: 700 }}>
                            {
                              <CountUp
                                separator=","
                                start={0}
                                preserveValue
                                delay={0}
                                end={Number(mineData.balanceCST)}
                                decimals={1}
                                duration={0.5}
                              />
                            }
                          </ContentText>
                        )}
                      </div>
                    </Box>
                    ~
                    <Box
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: isMobile ? '5px' : isTablet ? '10px' : '15px',
                          alignItems: 'center',
                        }}
                      >
                        {!account ? (
                          <Flex alignItems="center" gap="10px">
                            <img src="/images/core/ustdIcon.svg" />

                            <ContentText
                              style={{ fontSize: isMobile ? '15px' : '18px', fontWeight: 600, color: '#FFFFFF' }}
                            >
                              0 USDT
                            </ContentText>
                          </Flex>
                        ) : (
                          <Flex alignItems="center" gap="10px">
                            {!account ? (
                              <Flex alignItems="center" gap="10px">
                                <img src="/images/core/ustdIcon.svg" alt="" />
                                <ContentText
                                  style={{
                                    fontSize: isMobile ? '15px' : '18px',
                                    fontWeight: 600,
                                    color: '#FFFFFF',
                                  }}
                                >
                                  0 USDT
                                </ContentText>
                              </Flex>
                            ) : (
                              <ContentText
                                style={{
                                  fontSize: isMobile ? '15px' : '18px',
                                  fontWeight: 600,
                                  color: '#fff',
                                }}
                              >
                                <Flex gap="10px">
                                  <img src="/images/core/ustdIcon.svg" alt="" />
                                  <CountUp
                                    separator=","
                                    start={0}
                                    preserveValue
                                    delay={0}
                                    end={Number(mineData.balanceCST) * Number(formatEther(CST2USDT))}
                                    decimals={Number(mineData.balanceCST) > 0 ? 3 : 0}
                                    duration={0.5}
                                  />{' '}
                                  USDT
                                </Flex>
                              </ContentText>
                            )}
                          </Flex>
                        )}
                      </div>
                    </Box>
                  </Flex>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: isMobile ? '16px' : '32px',
                  order: isMobile ? 2 : 1,
                  width: '100%',
                }}
              >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <Flex justifyContent="space-between" w="full">
                    <ContentText>Available CST</ContentText>
                    <button
                      onClick={openClaimModal}
                      disabled={available.show === 0 || !account}
                      style={{
                        alignSelf: 'center',
                        background: 'none',
                        // backdropFilter: 'blur(50px)',
                      }}
                    >
                      <ContentText
                        style={{
                          fontWeight: 700,
                          fontSize: 16,
                          textTransform: 'uppercase',
                          background: 'linear-gradient(180deg, #FEF0AE 2.17%, #FAC600 108.56%)',
                          backgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                        }}
                      >
                        Claim
                      </ContentText>
                    </button>
                  </Flex>
                  <Flex alignItems="center" gap="10px">
                    <Box
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: isMobile ? '5px' : isTablet ? '10px' : '15px',
                          alignItems: 'center',
                        }}
                      >
                        <img src="/images/core/cstIcon.svg" alt="" />
                        {!account ? (
                          <ContentText
                            style={{
                              fontSize: isMobile ? '15px' : '30px',
                              fontWeight: 700,
                            }}
                          >
                            0
                          </ContentText>
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: '10px',
                            }}
                          >
                            <ContentText style={{ fontSize: isMobile ? '15px' : '18px', fontWeight: 700 }}>
                              <CountUp
                                start={0}
                                preserveValue
                                delay={0}
                                end={available.show}
                                decimals={available.show > 0 ? 4 : 0}
                                duration={0.5}
                                separator=","
                              />
                            </ContentText>
                          </div>
                        )}
                      </div>
                    </Box>
                    ~
                    <Box
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: isMobile ? '5px' : isTablet ? '10px' : '15px',
                          alignItems: 'center',
                        }}
                      >
                        {!account ? (
                          <Flex alignItems="center" gap="10px">
                            <img src="/images/core/ustdIcon.svg" />
                            <ContentText
                              style={{ fontSize: isMobile ? '15px' : '18px', fontWeight: 600, color: '#FFFFFF' }}
                            >
                              0 USDT
                            </ContentText>
                          </Flex>
                        ) : (
                          <ContentText
                            style={{
                              fontSize: isMobile ? '15px' : '18px',
                              fontWeight: 600,
                              color: '#fff',
                            }}
                          >
                            <Flex alignItems="center" gap="10px">
                              <img src="/images/core/ustdIcon.svg" />
                              <CountUp
                                start={0}
                                preserveValue
                                delay={0}
                                end={available.show * Number(formatEther(CST2USDT))}
                                decimals={available.show > 0 ? 4 : 0}
                                duration={0.5}
                                separator=","
                              />{' '}
                              USDT
                            </Flex>
                          </ContentText>
                        )}
                      </div>
                    </Box>
                  </Flex>
                </div>
              </div>
            </BoxContain>
          </Table>
          <Table>
            <BoxContain>
              <Flex
                direction={isMobile ? 'column' : 'row'}
                alignItems={isMobile ? 'start' : 'center'}
                justifyContent="space-between"
                gap="20px"
              >
                <Flex alignItems="center" gap="20px">
                  <img src="/images/core/systemFrame.svg" />
                  <ContentText
                    style={{
                      fontSize: '24px',
                      lineHeight: isMobile ? '32px' : '38px',
                      fontWeight: '700',
                      color: '#FFF',
                    }}
                  >
                    System
                  </ContentText>
                </Flex>

                {/* <Flex gap="10px" alignItems="center">
                  <Box
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      height: '50px',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      padding: '0 20px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: isMobile ? '5px' : isTablet ? '10px' : '15px',
                        alignItems: 'center',
                      }}
                    >
                      <img src="/images/core/minner.svg" />
                      <ContentText
                        style={{
                          fontSize: '12px',
                          fontWeight: 700,
                        }}
                      >
                        <CountUp
                          start={0}
                          preserveValue
                          delay={0}
                          end={Number(systemData.totalMiner)}
                          duration={0.5}
                          separator=","
                          style={{
                            color: '#E2E1E5',
                            fontSize: '25px',
                            fontWeight: '700',
                            lineHeight: '44px',
                          }}
                        />{' '}
                        Minner
                      </ContentText>{' '}
                    </div>
                  </Box>
                </Flex> */}
              </Flex>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: isMobile ? '16px' : '32px',
                  order: isMobile ? 2 : 1,
                  width: '100%',
                }}
              >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <ContentText>Total Mined</ContentText>
                  <Flex alignItems="center" gap="10px">
                    <Box
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: isMobile ? '5px' : isTablet ? '10px' : '15px',
                          alignItems: 'center',
                        }}
                      >
                        <img src="/images/core/cstIcon.svg" />
                        <ContentText
                          style={{
                            fontSize: isMobile ? '15px' : '18px',
                            fontWeight: 700,
                          }}
                        >
                          <CountUp
                            start={0}
                            preserveValue
                            delay={0}
                            end={Number(systemData.totalMined)}
                            decimals={Number(systemData.totalMined) > 0 ? 2 : 0}
                            duration={0.5}
                            separator=","
                          />{' '}
                          CST
                        </ContentText>{' '}
                      </div>
                    </Box>
                    ~
                    <Box
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: isMobile ? '5px' : isTablet ? '10px' : '15px',
                          alignItems: 'center',
                        }}
                      >
                        <Flex gap="10px">
                          <img src="/images/core/ustdIcon.svg" />
                          <ContentText
                            style={{
                              fontSize: isMobile ? '15px' : '18px',
                              fontWeight: 600,
                              color: '#fff',
                              alignItems: 'center',
                            }}
                          >
                            <CountUp
                              start={0}
                              preserveValue
                              delay={0}
                              end={Number(systemData.totalMined) * systemData.defaultTrend}
                              decimals={Number(systemData.totalMined) > 0 ? 4 : 0}
                              duration={0.5}
                              separator=","
                            />{' '}
                            USDT
                          </ContentText>
                        </Flex>
                      </div>
                    </Box>
                  </Flex>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: isMobile ? '16px' : '32px',
                  order: isMobile ? 2 : 1,
                  width: '100%',
                }}
              >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <ContentText>Total Mined</ContentText>
                  <Flex alignItems="center" gap="10px">
                    <Box
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: isMobile ? '5px' : isTablet ? '10px' : '15px',
                          alignItems: 'center',
                        }}
                      >
                        <Flex alignItems="center" gap="10px">
                          <img src="/images/core/cstIcon.svg" alt="" />
                          <ContentText
                            style={{
                              fontSize: isMobile ? '15px' : '18px',
                              fontWeight: 700,
                            }}
                          >
                            <CountUp
                              start={0}
                              preserveValue
                              delay={0}
                              end={Number(systemData.totalClaimed)}
                              decimals={Number(systemData.totalClaimed) > 0 ? 4 : 0}
                              duration={0.5}
                            />{' '}
                            CST
                          </ContentText>{' '}
                        </Flex>
                      </div>
                    </Box>
                    ~
                    <Box
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: isMobile ? '5px' : isTablet ? '10px' : '15px',
                          alignItems: 'center',
                        }}
                      >
                        <ContentText
                          style={{
                            fontSize: isMobile ? '15px' : '18px',
                            fontWeight: 600,
                            color: '#fff',
                          }}
                        >
                          <Flex gap="10px">
                            <img src="/images/core/ustdIcon.svg" alt="" />
                            <CountUp
                              start={0}
                              preserveValue
                              delay={0}
                              end={Number(systemData.totalClaimed) * systemData.defaultTrend}
                              decimals={Number(systemData.totalClaimed) > 0 ? 4 : 0}
                              duration={0.5}
                            />{' '}
                            USDT
                          </Flex>
                        </ContentText>
                      </div>
                    </Box>
                  </Flex>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: isMobile ? '16px' : '32px',
                  order: isMobile ? 2 : 1,
                  width: '100%',
                }}
              >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <ContentText>Exchange</ContentText>
                  <Flex alignItems="center" gap="10px">
                    <Box
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: isMobile ? '5px' : isTablet ? '10px' : '15px',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '10px',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <img src="/images/core/cstIcon.svg" />
                          <ContentText
                            style={{
                              fontSize: isMobile ? '15px' : '20px',
                              fontWeight: 700,
                            }}
                          >
                            1 CST
                          </ContentText>{' '}
                        </div>
                      </div>
                    </Box>
                    ~
                    <Box
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: isMobile ? '5px' : isTablet ? '10px' : '15px',
                          alignItems: 'center',
                        }}
                      >
                        <ContentText
                          style={{
                            fontSize: isMobile ? '15px' : '18px',
                            fontWeight: 600,
                            color: '#fff',
                          }}
                        >
                          <Flex alignItems="center" gap="10px">
                            <img src="/images/core/ustdIcon.svg" />
                            <CountUp
                              separator=","
                              start={0}
                              preserveValue
                              delay={0}
                              // end={Number(Number(balance) * rateBnbUsd)}
                              decimals={4}
                              duration={1}
                              end={Number(formatEther(CST2USDT))}
                            />{' '}
                            USDT
                          </Flex>
                        </ContentText>
                      </div>
                    </Box>
                  </Flex>
                </div>
              </div>
            </BoxContain>
          </Table>
        </Flex>
      </Container>

      {account && (
        <Text
          style={{
            color: '#fff',
            fontWeight: 600,
            paddingTop: isMobile ? '40px' : isTablet ? '40px' : isTabletOrDesktop ? '40px' : '300px',
            textAlign: 'start',
            maxWidth: '1240px',
            width: '96%',
            // padding: '0 10px'
          }}
          fontSize={responsiveTextSizeHeader}
        >
          Your Mined History
        </Text>
      )}
      {!account ? null : (
        <TableMine style={{ fontFamily: 'Poppins, sans-serif !important' }}>
          <TableDataPool
            mine={mineData}
            userClaimedMineLength={mineData.userClaimedMineLength}
            mineHistory={usersClaimed}
          />
        </TableMine>
      )}
    </Wrapper>
  )
}

export default Mining
