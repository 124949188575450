/* eslint-disable react/button-has-type */
import { formatEther, formatUnits } from '@ethersproject/units'
import { Button, LinkExternal, Text, useMatchBreakpoints, useModal, useToast } from '@pancakeswap/uikit'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import CircleLoader from 'components/Loader/CircleLoader'
import styled from 'styled-components'
import { color } from 'configs/color'
import { usePoolsContract } from 'hooks/useContract'
import React, { useEffect, useRef, useState } from 'react'
import CountUp from 'react-countup'
import numeral from 'numeral'
import TableDataPool from './components/yourMineHistory'
import ClaimPoolModal from './components/ClaimModal'
import { getContract } from 'utils/contractHelpers'
import addresses from 'config/constants/contracts'
import tokenCST from 'config/abi/tokenCore.json'
import { useSigner } from 'wagmi'
import SendTrendModal from './components/sendModal'
import { Text02, Text03 } from 'configs/fornt'
import { getBlockExploreLink } from 'utils'
import { ButtonStake } from 'components/ButtonStake'
import Breadcrumb from 'components/Breadcrumb'
import { Flex, Stack } from '@chakra-ui/react'
import USDTPoolsABI from 'config/abi/USDTPoolsABI.json'
import useConfirmTransaction from '../../hooks/useConfirmTransaction'
import useApproveConfirmTransaction from 'hooks/useApproveConfirmTransaction'
import { ethers } from 'ethers'
import { ToastDescriptionWithTx } from '../../components/Toast'
import { useCallWithGasPrice } from '../../hooks/useCallWithGasPrice'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
const responsiveTextSizeHeader = ['20px', '24px', '25px', '32px', '48px']

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  justify-content: center;
  // background: linear-gradient(90deg, #9e86ff 0%, #2b0864 111.24%);
  // background: black;
  background: url('/images/core/MiningCST.png') no-repeat;
  background-size: cover;
  margin-top: 50px;
  gap: 32px;
  /* height: 1200px; */
  margin-bottom: 20px;
  @media screen and (max-width: 575px) {
    margin-top: 50px;
    background-position: 50% 100%;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
  max-width: 1240px;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    margin-top: 0px !important;
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column !important;
  }
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }

  margin-top: 70px;
`

const IntroWapper = styled.div`
  display: flex;
  /* flex-direction: row; */
  justify-content: flex-start;
  align-items: start;
  .icon {
    margin-top: 60px;
    width: 24px;
    height: 24px;
  }
  .link:hover {
    text-decoration: none;
  }
  @media screen and (max-width: 575px) {
    flex-direction: column;
    .icon {
      margin-top: 30px;
    }
  }
`

const Title = styled.div`
  font-size: 60px;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: -0.02em;
  text-align: center;
  /* letter-spacing: -1.2px; */
  margin-top: 30px;
  color: white;
  @media screen and (max-width: 575px) {
    font-size: 24px;
    text-align: center;
    line-height: 32px;
  }
`

const IntrotText = styled.div`
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  width: 100%;
  @media screen and (max-width: 900px) {
    text-align: start;
  }
  @media screen and (max-width: 575px) {
    width: 90%;
    font-size: 15px;
    text-align: start;
    line-height: 18px;
  }
`

const Table = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  border-radius: 8px;
  border: 2px solid transparent;
  background-image: linear-gradient(#1d1617, #1d1617), linear-gradient(180deg, #ff9400 0%, #d97059 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
  .animation {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  .animation .hos-wrapper {
    background-image: url('/images/core/Oki1.png');
    background-size: cover;
    background-position: 50% 50%;
    width: 80%;
    height: 556px;
    position: relative;
  }
  .hos {
    position: absolute;
    width: 100%;
    height: 556px;
    top: 0;
    left: 0;
    object-fit: cover;
    animation: 2s linear 0s infinite forwards running hos;
    transform-box: fill-box;
    transform-origin: 50% 50%;
  }
  @keyframes hos {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    .animation .hos-wrapper {
      width: 80%;
      height: 556px;
    }
    .hos {
      width: 100%;
      height: 556px;
    }
  }
  @media screen and (max-width: 575px) {
    flex-direction: column;
    .animation .hos-wrapper {
      width: 100%;
      height: 356px;
    }
    .hos {
      width: 100%;
      height: 356px;
    }
  }
`
const BoxContain = styled.div`
  padding: 20px 40px;
  border-radius: 15px;
  border: 1px;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 900px) {
    gap: 16px;
    width: 100%;
    padding: 20px;
  }
  @media screen and (max-width: 575px) {
    gap: 16px;
    width: 100%;
    padding: 20px;
  }
`
const Box = styled.div`
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
`
const ContentText = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  @media screen and (max-width: 575px) {
    font-size: 20px;
  }
`

const TableMine = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 4px solid transparent;
  background-image: linear-gradient(#1d1617, #1d1617), linear-gradient(180deg, #f9b845 27%, #af452c 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  max-width: 1240px;
  width: 95%;
  backdrop-filter: blur(50px);
  height: 100%;
  margin-bottom: 150px;
`
const InputGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  width: 100%;
  // margin: 5px 0 10px 0;
  border-radius: 4px;
  border: 1px solid var(--Colors-Stroke-Border-Border, #2f2e39);
  background: #0a0b0c;
`
const InpuStake = styled.input`
  font-family: 'Blinker', sans-serif;
  background: none;
  border: none;
  color: ${color.textWhite};
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  height: 36px;
  &:focus-visible {
    outline-width: 0;
  }
`
const BreadcrumbTitle = styled(Text)`
  color: rgba(255, 255, 255, 0.7);
  opacity: 0.5;
  font-family: Poppins;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 575px) {
    margin-top: 30px;
  }
`
function Mining() {
  const { isMobile, isTablet, isTabletOrDesktop } = useMatchBreakpoints()
  let { account, chainId } = useActiveWeb3React()
  // account = '0x017Fd21D3CE675fA29C63e4b1e4Cb535948dF160'
  const { data: signer } = useSigner()
  const CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)
  const { callWithGasPrice } = useCallWithGasPrice()
  const getPoolsContractUSDTPools = getContract({
    address: addresses.USDTPools[CHAIN_ID],
    abi: USDTPoolsABI,
    chainId: CHAIN_ID,
    signer,
  })
  const tokenCSTCT = getContract({ address: addresses.tokenCore[CHAIN_ID], abi: tokenCST, chainId: CHAIN_ID, signer })
  const tokenUSDTCT = getContract({ address: addresses.tokenUSDT[CHAIN_ID], abi: tokenCST, chainId: CHAIN_ID, signer })
  const [isLoading, setIsLoading] = useState(false)
  const [globalData, setGlobalData] = useState({
    cstAmount: 0,
    mineAmount: 0,
    cstReward: 0,
    usdtApproveAmount: 0,
    topRewardPid: 0,
    remainComm2: 0,
    usermaxOutUSD: 0,
    userOutUSD: 0,
    percentage: 0,
    pools: [
      {
        min: 100,
        currentReward: 0,
        totalLock: 0,
      },
      {
        min: 1000,
        currentReward: 0,
        totalLock: 0,
      },
      {
        min: 5000,
        currentReward: 0,
        totalLock: 0,
      },
      {
        min: 10000,
        currentReward: 0,
        totalLock: 0,
      },
    ],
  })
  const [curentPid, setCurrentPid] = useState(0)
  const { toastSuccess, toastError } = useToast()
  const [amounts, setAmounts] = useState(0)
  const [minAmounts, setMinAmounts] = useState(0)
  const [accountBalance, setAccountBalance] = useState(0)
  const [claimCT, setClaimCT] = useState(getPoolsContractUSDTPools)
  const [usersClaimed, setUserClaimed] = useState([])
  const [mineData, setMineData] = useState({
    totalMined: 0,
    claimed: 0,
    remain: 0,
    mineSpeed: 0,
    startTime: 0,
    userClaimedMineLength: 0,
    currentReward: 0,
    CST2USDT: 0,
    balanceCST: 0,
  })
  const [available, setAvailable] = useState({
    total: 0,
    show: 0,
  })

  const isFirst = useRef(false)
  const { hasConfirmFailed, isConfirming, handleConfirm } = useConfirmTransaction({
    onConfirm: () => {
      return callWithGasPrice(getPoolsContractUSDTPools, 'claimRewards', [globalData.topRewardPid, account])
    },
    onSuccess: async ({ receipt }) => {
      getData()
      toastSuccess('Claim successfully !', <ToastDescriptionWithTx txHash={receipt.transactionHash} />)
    },
  })
  const {
    hasConfirmFailed: hasConfirmFailedComm,
    isConfirming: isConfirmingComm,
    handleConfirm: handleConfirmComm,
  } = useConfirmTransaction({
    onConfirm: () => {
      return callWithGasPrice(getPoolsContractUSDTPools, 'claimComm', [account])
    },
    onSuccess: async ({ receipt }) => {
      getData()
      toastSuccess('Claim successfully !', <ToastDescriptionWithTx txHash={receipt.transactionHash} />)
    },
  })
  const {
    hasConfirmFailed: hasConfirmFailedUSDT,
    isConfirming: isConfirmingUSDT,
    handleApprove,
    handleConfirm: handleConfirmUSDT,
  } = useApproveConfirmTransaction({
    onApprove: () => {
      return callWithGasPrice(tokenUSDTCT, 'approve', [addresses.USDTPools[chainId], 1000000000000000])
    },
    onConfirm: () => {
      // console.log([curentPid, globalData.pools[curentPid].min * 10 ** 6])
      return callWithGasPrice(getPoolsContractUSDTPools, 'deposit', [
        curentPid,
        globalData.pools[curentPid].min * 10 ** 6,
      ])
    },
    onSuccess: async ({ receipt }) => {
      getData()
      toastSuccess('Deposit successfully!', <ToastDescriptionWithTx txHash={receipt.transactionHash} />)
    },
    onApproveSuccess: async ({ receipt }) => {
      getData()
      toastSuccess('Approve successfully !', <ToastDescriptionWithTx txHash={receipt.transactionHash} />)
    },
  })
  const approveUSDT = (pid) => {
    setCurrentPid(pid)
    handleApprove()
  }
  const depositUSDT = (pid) => {
    setCurrentPid(pid)
    handleConfirmUSDT()
  }
  const updateAvailable = (avai) => {
    const newAvai = {
      ...avai,
      show: avai.show + ((avai.total - avai.show) * 10) / 100,
    }
    if (isFirst.current === true) {
      setTimeout(async () => {
        if (avai.show > 0) setAvailable(newAvai)
        updateAvailable(newAvai)
      }, 3000)
    } else {
      setAvailable({
        total: 0,
        show: 0,
      })
    }
  }

  const getData = async () => {
    const [balanceCST, balanceUSDT, allowance, remainComm2, usermaxOutUSD, userOutUSD] = await Promise.all([
      await tokenCSTCT.balanceOf(account),
      await tokenUSDTCT.balanceOf(account),
      await tokenUSDTCT.allowance(account, addresses.USDTPools[CHAIN_ID]),
      await getPoolsContractUSDTPools.remainComm2(account),
      await getPoolsContractUSDTPools.usermaxOutUSD(account),
      await getPoolsContractUSDTPools.userOutUSD(account),
    ])
    setAccountBalance(Number(formatUnits(balanceUSDT, 6)))
    const dataPools = [...globalData.pools]
    let tempRewardAmount = 0
    let tempRewardPid = 0
    // const poolsInfo = await getPoolsContractUSDTPools.getPools([0, 1, 2, 3])
    const currentRewards = await getPoolsContractUSDTPools.currentRewards(account).then((rs) => {
      rs.map(async (r, i) => {
        dataPools[i].currentReward = Number(formatEther(r))
        const userLocked = await getPoolsContractUSDTPools.users(account, i)
        // console.log(account, i, userLocked.totalLockUSD.toString())
        dataPools[i].totalLock = Number(formatUnits(userLocked.totalLockUSD.toString(), 6))
        if (dataPools[i].currentReward > tempRewardAmount) tempRewardPid = i
        return r
      })
      return rs.reduce((a, b) => a.add(b))
    })
    const usdt2cst = await getPoolsContractUSDTPools.USD2CST(currentRewards)
    setGlobalData({
      ...globalData,
      cstAmount: Number(formatEther(balanceCST)),
      usdtApproveAmount: Number(formatUnits(allowance, 6)),
      mineAmount: Number(formatUnits(currentRewards, 6)),
      cstReward: Number(formatEther(usdt2cst)),
      topRewardPid: tempRewardPid,
      remainComm2: Number(formatUnits(remainComm2, 6)),
      usermaxOutUSD: Number(formatUnits(usermaxOutUSD, 6)),
      userOutUSD: Number(formatUnits(userOutUSD, 6)),
      percentage:
        Number(formatUnits(usermaxOutUSD, 6)) > 0
          ? (Number(formatUnits(userOutUSD, 6)) / Number(formatUnits(usermaxOutUSD, 6))) * 100
          : 0,
      pools: dataPools,
    })
    getMineHistory(10)
  }
  const [openClaimModal, onDismissModal] = useModal(
    <ClaimPoolModal
      onDismiss={() => onDismissModal()}
      onSuccess={() => handleSuccess()}
      mine={mineData}
      claimCt={claimCT}
    />,
    true,
    false,
    'removeModal',
  )
  const handleSuccess = () => {
    getData()
  }
  const mergeData = (newData) => {
    console.log(newData)
    setUserClaimed((prevData) => [...prevData, ...newData])
  }
  const getMineHistory = async (getUsersClaimMinedLength) => {
    try {
      if (!account) {
        setIsLoading(true)
      } else {
        setIsLoading(false)

        if (Number(getUsersClaimMinedLength) > 0) {
          // const currenRewardV1 = await getPoolsContract.currentRewardCST(account)
          // const currenRewardV2 = await getPoolsContractV2.currentRewardCST(account)
          // const totalCurrentReward = Number(formatEther(currenRewardV1)) + Number(formatEther(currenRewardV2))
          // const CSTUSD = await getPoolsContract.CST2USDT()
          await getPoolsContractUSDTPools
            .getUsersClaimed(0, account, Number(getUsersClaimMinedLength), 0)
            .then(async (res) => {
              const arr = res.list.map((claimed: any, i: number) => {
                return {
                  date: Number(claimed.date.toString()),
                  amountUSD: Number(formatUnits(claimed.amountUSD, 6)),
                  totalLockUSD: Number(formatUnits(claimed.totalLockUSD, 6)),
                  interest: Number(claimed.interest),
                  // power: Number(claimed.interrest.toString()) / 100,
                  // currentReward: Number(totalCurrentReward),
                  // rateUSD: Number(formatEther(CSTUSD)),
                }
              })
              const p1 = await getPoolsContractUSDTPools.getUsersClaimed(
                1,
                account,
                Number(getUsersClaimMinedLength),
                0,
              )
              const arr1 = p1.list.map((claimed: any, i: number) => {
                return {
                  date: Number(claimed.date.toString()),
                  amountUSD: Number(formatUnits(claimed.amountUSD, 6)),
                  totalLockUSD: Number(formatUnits(claimed.totalLockUSD, 6)),
                  interest: Number(claimed.interest),
                  // power: Number(claimed.interrest.toString()) / 100,
                  // currentReward: Number(totalCurrentReward),
                  // rateUSD: Number(formatEther(CSTUSD)),
                }
              })
              // console.log(arr)

              setUserClaimed(arr.concat(p1))
            })
          // await getPoolsContractV2.getUsersClaimMined(account, Number(getUsersClaimMinedLengthV2), 0).then((res) => {
          //   const arrV2 = res.list.map((claimed: any, i: number) => {
          //     return {
          //       date: Number(claimed.date.toString()),
          //       amount: Number(formatEther(claimed.amount)),
          //       totalLock: Number(formatEther(claimed.totalLock)),
          //       power: Number(claimed.interrest.toString()) / 100,
          //       currentReward: Number(totalCurrentReward),
          //       rateUSD: Number(formatEther(CSTUSD)),
          //     }
          //   })
          //   mergeData(arrV2)
          // })
        }
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    if (account) {
      getData()
    }
  }, [account])
  useEffect(() => {
    getData()
  }, [account])
  useEffect(() => {
    if (!account) {
      setMineData({
        totalMined: 0,
        claimed: 0,
        remain: 0,
        mineSpeed: 0,
        startTime: 0,
        userClaimedMineLength: 0,
        currentReward: 0,
        CST2USDT: 0,
        balanceCST: 0,
      })
    }
  }, [account])
  const handleChange = (e, pid) => {
    const amount = Number(e.target.value)
    const data = { ...globalData }
    data.pools[pid].min = amount
    setGlobalData(data)
  }
  return (
    <Wrapper>
      <Container>
        <BreadcrumbTitle>
          <Breadcrumb
            fontSize="14px"
            links={[
              { title: 'Home', href: '/' },
              { title: 'Mining', href: '/mining' },
            ]}
            current="mining"
          />
        </BreadcrumbTitle>
        <IntroWapper>
          <Flex direction={isMobile ? 'column' : isTablet ? 'column' : 'row'} justifyContent="space-between" w="full">
            <Stack spacing={0}>
              <Flex alignItems="star" justifyContent="flex-star" gap="10px">
                <Title>Mining</Title>

                <LinkExternal
                  fontSize={['14px', '16px', '18px', '20px', '22px']}
                  href={getBlockExploreLink(tokenCSTCT.address, 'token', CHAIN_ID)}
                  ellipsis={true}
                  style={{ color: '#00F0E1' }}
                  color="#00F0E1"
                  className="link"
                >
                  <Title>$CST </Title>
                </LinkExternal>
              </Flex>
              <IntrotText>Enjoi cumulative interest rate APY up to 300%</IntrotText>
            </Stack>
          </Flex>
        </IntroWapper>
        <Flex direction={isMobile ? 'column' : isTablet ? 'column' : 'row'} mt="30px" gap="30px">
          <Flex
            mt={isMobile ? '20px' : 'none'}
            p="10px"
            borderRadius="8px"
            border="2px solid transparent"
            background="#1d1617"
            gap="30px"
            sx={{
              backgroundImage: 'linear-gradient(#1d1617, #1d1617), linear-gradient(180deg, #F9B845 27%, #AF452C 100%)',
              backgroundOrigin: 'border-box',
              backgroundClip: 'padding-box, border-box',
            }}
            style={{ display: 'block' }}
          >
            <div style={{ display: 'flex' }}>
              <img style={{ width: 80 }} src="/images/core/logocst.svg" />
              <Stack spacing={0}>
                <Text style={{ lineHeight: !isMobile ? '90px' : '', marginLeft: 20 }}>
                  Rewards are exclusively reserved for refferals. Invite your friends and get our rewards
                </Text>
              </Stack>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: isMobile ? '16px' : '32px',
                order: isMobile ? 2 : 1,
              }}
            >
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <Flex
                  alignItems="center"
                  gap="10px"
                  style={{ display: !isMobile ? 'flex' : 'contents', margin: '20px 0' }}
                >
                  <Box
                    style={{
                      width: '100%',
                      minWidth: 130,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      padding: '10px 20px',
                    }}
                  >
                    <div
                      style={{
                        flexDirection: 'row',
                        gap: isMobile ? '5px' : isTablet ? '10px' : '15px',
                        alignItems: 'center',
                      }}
                    >
                      <ContentText>CST Amount</ContentText>
                      {!account ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '10px',
                          }}
                        >
                          <ContentText
                            style={{
                              fontSize: isMobile ? '15px' : '18px',
                              fontWeight: 700,
                            }}
                          >
                            0
                          </ContentText>{' '}
                        </div>
                      ) : (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: '10px',
                            }}
                          >
                            <ContentText
                              style={{
                                fontSize: isMobile ? '15px' : '18px',
                                fontWeight: 700,
                              }}
                            >
                              <CountUp
                                start={0}
                                preserveValue
                                delay={0}
                                end={globalData.cstAmount}
                                decimals={mineData.totalMined > 0 ? 1 : 0}
                                duration={0.5}
                                separator=","
                              />{' '}
                              CST
                            </ContentText>
                          </div>
                        </>
                      )}
                    </div>
                  </Box>
                  <Box
                    style={{
                      width: '100%',
                      minWidth: 150,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      padding: '10px 20px',
                    }}
                  >
                    <div
                      style={{
                        flexDirection: 'row',
                        gap: isMobile ? '5px' : isTablet ? '10px' : '15px',
                        alignItems: 'center',
                      }}
                    >
                      <ContentText>Mining Amount</ContentText>
                      <Flex alignItems="center" gap="10px">
                        <img src="/images/core/ustdIcon.svg" />

                        <ContentText
                          style={{
                            fontSize: isMobile ? '15px' : '18px',
                            fontWeight: 600,
                            color: '#fff',
                          }}
                        >
                          <CountUp
                            start={0}
                            preserveValue
                            delay={0}
                            end={globalData.mineAmount}
                            decimals={globalData.mineAmount > 0 ? 3 : 0}
                            duration={0.5}
                            separator=","
                          />{' '}
                        </ContentText>
                      </Flex>
                    </div>
                  </Box>
                  <Box
                    style={{
                      width: '100%',
                      minWidth: 130,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      padding: '10px 20px',
                    }}
                  >
                    <div
                      style={{
                        flexDirection: 'row',
                        gap: isMobile ? '5px' : isTablet ? '10px' : '15px',
                        alignItems: 'center',
                      }}
                    >
                      <ContentText>CST Reward</ContentText>
                      <>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '10px',
                          }}
                        >
                          <ContentText
                            style={{
                              fontSize: isMobile ? '15px' : '18px',
                              fontWeight: 700,
                            }}
                          >
                            <CountUp
                              start={0}
                              preserveValue
                              delay={0}
                              end={globalData.cstReward}
                              decimals={globalData.cstReward > 0 ? 1 : 0}
                              duration={0.5}
                              separator=","
                            />{' '}
                            CST
                          </ContentText>
                        </div>
                      </>
                    </div>
                  </Box>
                </Flex>
                <Flex alignItems="center" gap="10px" style={{ display: !isMobile ? 'flex' : 'contents' }}>
                  <Box
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      padding: '10px 20px',
                    }}
                  >
                    <div
                      style={{
                        flexDirection: 'row',
                        gap: isMobile ? '5px' : isTablet ? '10px' : '15px',
                        alignItems: 'center',
                      }}
                    >
                      <Flex alignItems="center" gap="10px">
                        <ContentText
                          style={{ fontSize: isMobile ? '15px' : '18px', fontWeight: 600, color: '#FFFFFF' }}
                        >
                          Building Trust, One Block at a Time
                        </ContentText>
                      </Flex>
                    </div>
                  </Box>
                  <Box
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      padding: '10px 20px',
                    }}
                  >
                    <div
                      style={{
                        flexDirection: 'row',
                        gap: isMobile ? '5px' : isTablet ? '10px' : '15px',
                        alignItems: 'center',
                      }}
                    >
                      <Flex alignItems="center" gap="10px">
                        <ContentText
                          style={{ fontSize: isMobile ? '15px' : '18px', fontWeight: 600, color: '#FFFFFF' }}
                        >
                          Discover the future of CST Token
                        </ContentText>
                      </Flex>
                    </div>
                  </Box>
                </Flex>
                <Flex
                  alignItems="center"
                  gap="10px"
                  style={{ display: !isMobile ? 'flex' : 'contents', marginTop: 10 }}
                >
                  <Box
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      padding: '10px 20px',
                    }}
                  >
                    <div
                      style={{
                        flexDirection: 'row',
                        gap: isMobile ? '5px' : isTablet ? '10px' : '15px',
                        alignItems: 'center',
                      }}
                    >
                      <Flex alignItems="center" gap="10px">
                        <LinkExternal
                          fontSize={['14px', '16px', '18px', '20px', '22px']}
                          href={
                            'https://icecreamswap.com/swap?outputCurrency=0x900101d06A7426441Ae63e9AB3B9b0F63Be145F1'
                          }
                          ellipsis={true}
                          style={{ color: '#00F0E1' }}
                          color="#00F0E1"
                          className="link"
                        >
                          <Title style={{ margin: 0, fontSize: 18, color: '#00F0E1', lineHeight: '28px' }}>
                            Swap CORE -&gt; USDT{' '}
                          </Title>
                        </LinkExternal>
                      </Flex>
                    </div>
                  </Box>
                  <Box
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      padding: '10px 20px',
                    }}
                  >
                    <div
                      style={{
                        flexDirection: 'row',
                        gap: isMobile ? '5px' : isTablet ? '10px' : '15px',
                        alignItems: 'center',
                      }}
                    >
                      <Flex alignItems="center" gap="10px">
                        <LinkExternal
                          fontSize={['14px', '16px', '18px', '20px', '22px']}
                          href={'https://bridge.coredao.org/bridge'}
                          ellipsis={true}
                          style={{ color: '#00F0E1' }}
                          color="#00F0E1"
                          className="link"
                        >
                          <Title style={{ margin: 0, fontSize: 18, color: '#00F0E1', lineHeight: '28px' }}>
                            Bridge USDT{' '}
                          </Title>
                        </LinkExternal>
                      </Flex>
                    </div>
                  </Box>
                </Flex>
              </div>
            </div>
          </Flex>
          <img
            style={{ marginLeft: 20, width: 400, display: isMobile ? 'none' : 'block' }}
            src="/images/core/nextologocst.svg"
          />
        </Flex>
        <Flex direction={isMobile ? 'column' : isTablet ? 'column' : 'row'} mt="30px" gap="30px">
          <Table>
            <BoxContain>
              <Text>
                Balance:{' '}
                <span
                  style={{
                    background: 'linear-gradient(180deg, #FEF0AE 2.17%, #FAC600 108.56%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  {accountBalance > 100 ? numeral(accountBalance).format('0,0') : accountBalance.toFixed(4)} USDT
                </span>
              </Text>
              <InputGroup>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}></div>
                <InpuStake onChange={(e) => handleChange(e, 0)} value={globalData.pools[0].min}></InpuStake>

                <div
                  // onClick={() => setAmounts(Number(Number(accountBalance).toFixed(3)))}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    // cursor: 'pointer',
                    // background: '#535353',
                    padding: '6px',
                    borderTopRightRadius: '10px',
                    borderBottomRightRadius: '10px',
                  }}
                >
                  {/* <img style={{ width: '24px', height: '24px' }} src="/images/core/IconCore.png" /> */}
                  <Flex p="8px 25px 8px 10px" bg="#2F292A" borderRadius="4px" gap="10px">
                    <img src="/images/core/ustdIcon.svg" />
                    <div
                      style={{
                        fontSize: 'Poppins,sans-serif',
                        fontWeight: 400,
                        color: '#fff',
                      }}
                    >
                      USDT
                    </div>
                  </Flex>
                </div>
              </InputGroup>
              {amounts < minAmounts && (
                <Text
                  style={{
                    color: 'red',
                  }}
                >
                  Minimum is {minAmounts}
                </Text>
              )}
              <Flex
                direction={isMobile ? 'column' : 'row'}
                style={{ marginTop: 10 }}
                justifyContent="center"
                gap="10px"
              >
                <Stack borderRadius="4px" p="5px 12px" bg="#2F292A" w="full">
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={400}
                    lineHeight="24px"
                  >
                    Min Mining 1
                  </Text>
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={600}
                    lineHeight="24px"
                  >
                    $100
                  </Text>
                </Stack>
                <Stack borderRadius="4px" p="5px 12px" bg="#2F292A" w="full">
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={400}
                    lineHeight="24px"
                  >
                    Max Mining 1
                  </Text>
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={600}
                    lineHeight="24px"
                  >
                    $999
                  </Text>
                </Stack>
              </Flex>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: isMobile ? '16px' : '32px',
                  order: isMobile ? 2 : 1,
                  width: '100%',
                }}
              >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Flex alignItems="center" gap="10px">
                    <ContentText
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      Interest/Month
                    </ContentText>
                    <ContentText
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      7.5%
                    </ContentText>
                  </Flex>
                  <Flex alignItems="center" gap="10px">
                    <ContentText
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      Interest rate APY
                    </ContentText>
                    <ContentText
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      200%
                    </ContentText>
                  </Flex>
                  <Flex alignItems="center" gap="10px">
                    <ContentText
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      Total Lock
                    </ContentText>
                    <ContentText
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      ${globalData.pools[0].totalLock}
                    </ContentText>
                  </Flex>
                  <ContentText>USD Delegated Now</ContentText>
                  {globalData.usdtApproveAmount <= globalData.pools[0].min ? (
                    <Button
                      style={{
                        width: '100%',
                        borderRadius: '4px',
                        background: '#FF9400',
                        color: '#fff',
                      }}
                      onClick={() => approveUSDT(0)}
                    >
                      {isConfirmingUSDT ? (
                        <Stack justifyContent="center" alignItems="center">
                          <CircleLoader size="30px" stroke="white" />
                        </Stack>
                      ) : hasConfirmFailedUSDT ? (
                        <Text fontWeight={600}>Approve USDT</Text>
                      ) : (
                        <Text fontWeight={600}>Approve USDT</Text>
                      )}
                    </Button>
                  ) : (
                    <Button
                      style={{
                        width: '100%',
                        borderRadius: '4px',
                        background: '#FF9400',
                        color: '#fff',
                      }}
                      onClick={() => depositUSDT(0)}
                      // disabled={
                      //   showError || Number(amounts) <= 0 || coreAmount > Number(accountBalance) || amounts < minAmounts
                      // }
                    >
                      {isConfirmingUSDT ? (
                        <Stack justifyContent="center" alignItems="center">
                          <CircleLoader size="30px" stroke="white" />
                        </Stack>
                      ) : hasConfirmFailedUSDT ? (
                        <Text fontWeight={600}>Mining CST</Text>
                      ) : (
                        <Text fontWeight={600}>Mining CST</Text>
                      )}
                    </Button>
                  )}
                </div>
              </div>
            </BoxContain>
          </Table>
          <Table>
            <BoxContain>
              <Text>
                Balance:{' '}
                <span
                  style={{
                    background: 'linear-gradient(180deg, #FEF0AE 2.17%, #FAC600 108.56%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  {accountBalance > 100 ? numeral(accountBalance).format('0,0') : accountBalance.toFixed(4)} USDT
                </span>
              </Text>
              <InputGroup>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}></div>
                <InpuStake onChange={(e) => handleChange(e, 1)} value={globalData.pools[1].min}></InpuStake>
                <div
                  // onClick={() => setAmounts(Number(Number(accountBalance).toFixed(3)))}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    // cursor: 'pointer',
                    // background: '#535353',
                    padding: '6px',
                    borderTopRightRadius: '10px',
                    borderBottomRightRadius: '10px',
                  }}
                >
                  {/* <img style={{ width: '24px', height: '24px' }} src="/images/core/IconCore.png" /> */}
                  <Flex p="8px 25px 8px 10px" bg="#2F292A" borderRadius="4px" gap="10px">
                    <img src="/images/core/ustdIcon.svg" />
                    <div
                      style={{
                        fontSize: 'Poppins,sans-serif',
                        fontWeight: 400,
                        color: '#fff',
                      }}
                    >
                      USDT
                    </div>
                  </Flex>
                </div>
              </InputGroup>
              {amounts < minAmounts && (
                <Text
                  style={{
                    color: 'red',
                  }}
                >
                  Minimum is {minAmounts}
                </Text>
              )}
              <Flex
                direction={isMobile ? 'column' : 'row'}
                style={{ marginTop: 10 }}
                justifyContent="center"
                gap="10px"
              >
                <Stack borderRadius="4px" p="5px 12px" bg="#2F292A" w="full">
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={400}
                    lineHeight="24px"
                  >
                    Min Mining 2
                  </Text>
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={600}
                    lineHeight="24px"
                  >
                    $1,000
                  </Text>
                </Stack>
                <Stack borderRadius="4px" p="5px 12px" bg="#2F292A" w="full">
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={400}
                    lineHeight="24px"
                  >
                    Max Mining 2
                  </Text>
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={600}
                    lineHeight="24px"
                  >
                    $4,999
                  </Text>
                </Stack>
              </Flex>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: isMobile ? '16px' : '32px',
                  order: isMobile ? 2 : 1,
                  width: '100%',
                }}
              >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Flex alignItems="center" gap="10px">
                    <ContentText
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      Interest/Month
                    </ContentText>
                    <ContentText
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      9%
                    </ContentText>
                  </Flex>
                  <Flex alignItems="center" gap="10px">
                    <ContentText
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      Interest rate APY
                    </ContentText>
                    <ContentText
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      200%
                    </ContentText>
                  </Flex>
                  <Flex alignItems="center" gap="10px">
                    <ContentText
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      Total Lock
                    </ContentText>
                    <ContentText
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      ${globalData.pools[1].totalLock}
                    </ContentText>
                  </Flex>
                  <ContentText>USD Delegated Now</ContentText>
                  {globalData.usdtApproveAmount <= globalData.pools[1].min ? (
                    <Button
                      style={{
                        width: '100%',
                        borderRadius: '4px',
                        background: '#FF9400',
                        color: '#fff',
                      }}
                      onClick={() => approveUSDT(1)}
                    >
                      {isConfirmingUSDT ? (
                        <Stack justifyContent="center" alignItems="center">
                          <CircleLoader size="30px" stroke="white" />
                        </Stack>
                      ) : hasConfirmFailedUSDT ? (
                        <Text fontWeight={600}>Approve USDT</Text>
                      ) : (
                        <Text fontWeight={600}>Approve USDT</Text>
                      )}
                    </Button>
                  ) : (
                    <Button
                      style={{
                        width: '100%',
                        borderRadius: '4px',
                        background: '#FF9400',
                        color: '#fff',
                      }}
                      onClick={() => depositUSDT(1)}
                      // disabled={
                      //   showError || Number(amounts) <= 0 || coreAmount > Number(accountBalance) || amounts < minAmounts
                      // }
                    >
                      {isConfirmingUSDT ? (
                        <Stack justifyContent="center" alignItems="center">
                          <CircleLoader size="30px" stroke="white" />
                        </Stack>
                      ) : hasConfirmFailedUSDT ? (
                        <Text fontWeight={600}>Mining CST</Text>
                      ) : (
                        <Text fontWeight={600}>Mining CST</Text>
                      )}
                    </Button>
                  )}
                </div>
              </div>
            </BoxContain>
          </Table>
        </Flex>
        <Flex direction={isMobile ? 'column' : isTablet ? 'column' : 'row'} mt="30px" gap="30px">
          <Table>
            <BoxContain>
              <Text>
                Balance:{' '}
                <span
                  style={{
                    background: 'linear-gradient(180deg, #FEF0AE 2.17%, #FAC600 108.56%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  {accountBalance > 100 ? numeral(accountBalance).format('0,0') : accountBalance.toFixed(4)} USDT
                </span>
              </Text>
              <InputGroup>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}></div>
                <InpuStake onChange={(e) => handleChange(e, 2)} value={globalData.pools[2].min}></InpuStake>

                <div
                  // onClick={() => setAmounts(Number(Number(accountBalance).toFixed(3)))}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    // cursor: 'pointer',
                    // background: '#535353',
                    padding: '6px',
                    borderTopRightRadius: '10px',
                    borderBottomRightRadius: '10px',
                  }}
                >
                  {/* <img style={{ width: '24px', height: '24px' }} src="/images/core/IconCore.png" /> */}
                  <Flex p="8px 25px 8px 10px" bg="#2F292A" borderRadius="4px" gap="10px">
                    <img src="/images/core/ustdIcon.svg" />
                    <div
                      style={{
                        fontSize: 'Poppins,sans-serif',
                        fontWeight: 400,
                        color: '#fff',
                      }}
                    >
                      USDT
                    </div>
                  </Flex>
                </div>
              </InputGroup>
              {amounts < minAmounts && (
                <Text
                  style={{
                    color: 'red',
                  }}
                >
                  Minimum is {minAmounts}
                </Text>
              )}
              <Flex
                direction={isMobile ? 'column' : 'row'}
                style={{ marginTop: 10 }}
                justifyContent="center"
                gap="10px"
              >
                <Stack borderRadius="4px" p="5px 12px" bg="#2F292A" w="full">
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={400}
                    lineHeight="24px"
                  >
                    Min Mining 3
                  </Text>
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={600}
                    lineHeight="24px"
                  >
                    $5,000
                  </Text>
                </Stack>
                <Stack borderRadius="4px" p="5px 12px" bg="#2F292A" w="full">
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={400}
                    lineHeight="24px"
                  >
                    Max Mining 3
                  </Text>
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={600}
                    lineHeight="24px"
                  >
                    $9,999
                  </Text>
                </Stack>
              </Flex>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: isMobile ? '16px' : '32px',
                  order: isMobile ? 2 : 1,
                  width: '100%',
                }}
              >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Flex alignItems="center" gap="10px">
                    <ContentText
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      Interest/Month
                    </ContentText>
                    <ContentText
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      10.5%
                    </ContentText>
                  </Flex>
                  <Flex alignItems="center" gap="10px">
                    <ContentText
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      Interest rate APY
                    </ContentText>
                    <ContentText
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      200%
                    </ContentText>
                  </Flex>
                  <Flex alignItems="center" gap="10px">
                    <ContentText
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      Total Lock
                    </ContentText>
                    <ContentText
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      ${globalData.pools[2].totalLock}
                    </ContentText>
                  </Flex>
                  <ContentText>USD Delegated Now</ContentText>
                  {globalData.usdtApproveAmount <= globalData.pools[2].min ? (
                    <Button
                      style={{
                        width: '100%',
                        borderRadius: '4px',
                        background: '#FF9400',
                        color: '#fff',
                      }}
                      onClick={() => approveUSDT(2)}
                    >
                      {isConfirmingUSDT ? (
                        <Stack justifyContent="center" alignItems="center">
                          <CircleLoader size="30px" stroke="white" />
                        </Stack>
                      ) : hasConfirmFailedUSDT ? (
                        <Text fontWeight={600}>Approve USDT</Text>
                      ) : (
                        <Text fontWeight={600}>Approve USDT</Text>
                      )}
                    </Button>
                  ) : (
                    <Button
                      style={{
                        width: '100%',
                        borderRadius: '4px',
                        background: '#FF9400',
                        color: '#fff',
                      }}
                      onClick={() => depositUSDT(2)}
                      // disabled={
                      //   showError || Number(amounts) <= 0 || coreAmount > Number(accountBalance) || amounts < minAmounts
                      // }
                    >
                      {isConfirmingUSDT ? (
                        <Stack justifyContent="center" alignItems="center">
                          <CircleLoader size="30px" stroke="white" />
                        </Stack>
                      ) : hasConfirmFailedUSDT ? (
                        <Text fontWeight={600}>Mining CST</Text>
                      ) : (
                        <Text fontWeight={600}>Mining CST</Text>
                      )}
                    </Button>
                  )}
                </div>
              </div>
            </BoxContain>
          </Table>
          <Table>
            <BoxContain>
              <Text>
                Balance:{' '}
                <span
                  style={{
                    background: 'linear-gradient(180deg, #FEF0AE 2.17%, #FAC600 108.56%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  {accountBalance > 100 ? numeral(accountBalance).format('0,0') : accountBalance.toFixed(4)} USDT
                </span>
              </Text>
              <InputGroup>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}></div>
                <InpuStake onChange={(e) => handleChange(e, 3)} value={globalData.pools[3].min}></InpuStake>

                <div
                  // onClick={() => setAmounts(Number(Number(accountBalance).toFixed(3)))}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    // cursor: 'pointer',
                    // background: '#535353',
                    padding: '6px',
                    borderTopRightRadius: '10px',
                    borderBottomRightRadius: '10px',
                  }}
                >
                  {/* <img style={{ width: '24px', height: '24px' }} src="/images/core/IconCore.png" /> */}
                  <Flex p="8px 25px 8px 10px" bg="#2F292A" borderRadius="4px" gap="10px">
                    <img src="/images/core/ustdIcon.svg" />
                    <div
                      style={{
                        fontSize: 'Poppins,sans-serif',
                        fontWeight: 400,
                        color: '#fff',
                      }}
                    >
                      USDT
                    </div>
                  </Flex>
                </div>
              </InputGroup>
              {amounts < minAmounts && (
                <Text
                  style={{
                    color: 'red',
                  }}
                >
                  Minimum is {minAmounts}
                </Text>
              )}
              <Flex
                direction={isMobile ? 'column' : 'row'}
                style={{ marginTop: 10 }}
                justifyContent="center"
                gap="10px"
              >
                <Stack borderRadius="4px" p="5px 12px" bg="#2F292A" w="full">
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={400}
                    lineHeight="24px"
                  >
                    Min Mining 4
                  </Text>
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={600}
                    lineHeight="24px"
                  >
                    $10,000
                  </Text>
                </Stack>
                <Stack borderRadius="4px" p="5px 12px" bg="#2F292A" w="full">
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={400}
                    lineHeight="24px"
                  >
                    Max Mining 4
                  </Text>
                  <Text
                    color="#FFF"
                    fontFamily="Poppins,sans-serif"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={600}
                    lineHeight="24px"
                  >
                    $20,000
                  </Text>
                </Stack>
              </Flex>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: isMobile ? '16px' : '32px',
                  order: isMobile ? 2 : 1,
                  width: '100%',
                }}
              >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Flex alignItems="center" gap="10px">
                    <ContentText
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      Interest/Month
                    </ContentText>
                    <ContentText
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      12%
                    </ContentText>
                  </Flex>
                  <Flex alignItems="center" gap="10px">
                    <ContentText
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      Interest rate APY
                    </ContentText>
                    <ContentText
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      200%
                    </ContentText>
                  </Flex>
                  <Flex alignItems="center" gap="10px">
                    <ContentText
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      Total Lock
                    </ContentText>
                    <ContentText
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '0 20px',
                      }}
                    >
                      ${globalData.pools[3].totalLock}
                    </ContentText>
                  </Flex>
                  <ContentText>USD Delegated Now</ContentText>
                  {globalData.usdtApproveAmount <= globalData.pools[3].min ? (
                    <Button
                      style={{
                        width: '100%',
                        borderRadius: '4px',
                        background: '#FF9400',
                        color: '#fff',
                      }}
                      onClick={() => approveUSDT(3)}
                    >
                      {isConfirmingUSDT ? (
                        <Stack justifyContent="center" alignItems="center">
                          <CircleLoader size="30px" stroke="white" />
                        </Stack>
                      ) : hasConfirmFailedUSDT ? (
                        <Text fontWeight={600}>Approve USDT</Text>
                      ) : (
                        <Text fontWeight={600}>Approve USDT</Text>
                      )}
                    </Button>
                  ) : (
                    <Button
                      style={{
                        width: '100%',
                        borderRadius: '4px',
                        background: '#FF9400',
                        color: '#fff',
                      }}
                      onClick={() => depositUSDT(3)}
                      // disabled={
                      //   showError || Number(amounts) <= 0 || coreAmount > Number(accountBalance) || amounts < minAmounts
                      // }
                    >
                      {isConfirmingUSDT ? (
                        <Stack justifyContent="center" alignItems="center">
                          <CircleLoader size="30px" stroke="white" />
                        </Stack>
                      ) : hasConfirmFailedUSDT ? (
                        <Text fontWeight={600}>Mining CST</Text>
                      ) : (
                        <Text fontWeight={600}>Mining CST</Text>
                      )}
                    </Button>
                  )}
                </div>
              </div>
            </BoxContain>
          </Table>
        </Flex>
        <Flex direction={isMobile ? 'column' : isTablet ? 'column' : 'row'} mt="30px" gap="30px">
          <div
            style={{
              width: '40%',
              margin: isMobile ? 'auto' : 0,
            }}
          >
            <CircularProgressbar
              value={globalData.percentage}
              text={`${globalData.percentage.toFixed(1)}%`}
              strokeWidth={5}
              styles={buildStyles({
                pathColor: '#FF9500',
                textColor: '#fff',
                trailColor: '#fff',
                textSize: '25px',
              })}
            />
          </div>
          <Flex
            mt={isMobile ? '20px' : 'none'}
            p="10px"
            borderRadius="8px"
            border="2px solid transparent"
            background="#1d1617"
            gap="30px"
            sx={{
              backgroundImage: 'linear-gradient(#1d1617, #1d1617), linear-gradient(180deg, #F9B845 27%, #AF452C 100%)',
              backgroundOrigin: 'border-box',
              backgroundClip: 'padding-box, border-box',
            }}
            style={{ display: 'block', width: '100%', margin: 0 }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: isMobile ? '16px' : '32px',
                order: isMobile ? 2 : 1,
                marginTop: isMobile ? '0px' : '70px',
              }}
            >
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <Flex alignItems="center" gap="10px" style={{ display: !isMobile ? 'flex' : 'contents' }}>
                  <Box
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      padding: '10px 20px',
                    }}
                  >
                    <div
                      style={{
                        flexDirection: 'row',
                        gap: isMobile ? '5px' : isTablet ? '10px' : '15px',
                        alignItems: 'center',
                      }}
                    >
                      <Flex alignItems="center" gap="10px">
                        <ContentText
                          style={{ fontSize: isMobile ? '15px' : '18px', fontWeight: 600, color: '#FFFFFF' }}
                        >
                          Commission $
                          <CountUp
                            start={0}
                            preserveValue
                            delay={0}
                            end={globalData.remainComm2}
                            decimals={globalData.remainComm2 > 0 ? 3 : 0}
                            duration={0.5}
                            separator=","
                          />{' '}
                        </ContentText>
                      </Flex>
                    </div>
                  </Box>
                  <Button
                    style={{
                      width: '100%',
                      borderRadius: '4px',
                      background: '#FF9400',
                      color: '#fff',
                      marginTop: isMobile ? '10px' : '0px',
                    }}
                    onClick={handleConfirmComm}
                    // disabled={
                    //   showError || Number(amounts) <= 0 || coreAmount > Number(accountBalance) || amounts < minAmounts
                    // }
                  >
                    {isConfirmingComm ? (
                      <Stack justifyContent="center" alignItems="center">
                        <CircleLoader size="30px" stroke="white" />
                      </Stack>
                    ) : hasConfirmFailedComm ? (
                      <Text fontWeight={600}>Claim Commission</Text>
                    ) : (
                      <Text fontWeight={600}>Claim Commission</Text>
                    )}
                  </Button>
                </Flex>
              </div>
            </div>
          </Flex>
        </Flex>
      </Container>

      <Text
        style={{
          color: '#fff',
          fontWeight: 600,
          textAlign: 'start',
          maxWidth: '1240px',
          width: '96%',
          // padding: '0 10px'
        }}
        fontSize={responsiveTextSizeHeader}
      >
        Mining Records
      </Text>
      <Flex alignItems="center" gap="10px" style={{ display: !isMobile ? 'flex' : 'contents', width: '100%' }}>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            padding: '10px 20px',
          }}
        >
          <ContentText style={{ fontSize: isMobile ? '15px' : '18px', fontWeight: 600, color: '#FFFFFF' }}>
            <CountUp
              start={0}
              preserveValue
              delay={0}
              end={globalData.mineAmount}
              decimals={globalData.mineAmount > 0 ? 3 : 0}
              duration={0.5}
              separator=","
            />{' '}
            USD
          </ContentText>
        </Box>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            padding: '10px 20px',
          }}
        >
          <ContentText style={{ fontSize: isMobile ? '15px' : '18px', fontWeight: 600, color: '#FFFFFF' }}>
            <CountUp
              start={0}
              preserveValue
              delay={0}
              end={globalData.cstReward}
              decimals={globalData.cstReward > 0 ? 1 : 0}
              duration={0.5}
              separator=","
            />{' '}
            CST
          </ContentText>
        </Box>
        <Button
          style={{
            width: '100%',
            borderRadius: '4px',
            background: '#FF9400',
            color: '#fff',
            marginTop: isMobile ? '10px' : '0px',
          }}
          onClick={handleConfirm}
          disabled={globalData.cstReward <= 0}
        >
          {isConfirming ? (
            <Stack justifyContent="center" alignItems="center">
              <CircleLoader size="30px" stroke="white" />
            </Stack>
          ) : hasConfirmFailed ? (
            <Text fontWeight={600}>Claim</Text>
          ) : (
            <Text fontWeight={600}>Claim</Text>
          )}
        </Button>
      </Flex>
      {!account ? null : (
        <TableMine style={{ fontFamily: 'Poppins, sans-serif !important' }}>
          <TableDataPool
            mine={mineData}
            userClaimedMineLength={mineData.userClaimedMineLength}
            mineHistory={usersClaimed}
          />
        </TableMine>
      )}
    </Wrapper>
  )
}

export default Mining
