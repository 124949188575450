import { ChainId } from '@pancakeswap/sdk'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'
import { VaultKey } from 'state/types'

export const getAddress = (address: Address, chainId?: number): string => {
  return address[chainId] ? address[chainId] : address[ChainId.BSC]
}

export const getMulticallAddress = (chainId?: number) => {
  return getAddress(addresses.multiCall, chainId)
}
export const getVaultPoolAddress = (vaultKey: VaultKey) => {
  if (!vaultKey) {
    return null
  }
  return getAddress(addresses[vaultKey])
}
// TD Solution
export const getPoolsAddress = (chainId?: number) => {
  return getAddress(addresses.pools, chainId)
}
export const getPoolsV2Address = (chainId?: number) => {
  return getAddress(addresses.poolsV2, chainId)
}
export const getRefferalAddress = (chainId?: number) => {
  return getAddress(addresses.refferal, chainId)
}
export const getTokenCoreAddress = (chainId?: number) => {
  return getAddress(addresses.tokenCore, chainId)
}
